import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
  ListItemIcon,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
  Stack,
  Select,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  InputLabel,
  CssBaseline,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  CircularProgress,
  Menu,
  TextField,
  InputAdornment,
  SvgIcon,
  Skeleton,
  Breadcrumbs,
} from "@mui/material";
import {
  AccountBalanceWalletOutlined,
  GridViewOutlined,
  ApartmentOutlined,
  TrendingUp,
  TrendingDown,
  Remove,
  PieChart,
  Close as CloseIcon,
  CloudUpload,
  Dashboard,
  FileDownload,
  CheckCircle,
  RadioButtonUnchecked,
  AccessTime,
  AutoAwesome,
  Campaign,
  Delete,
  ArrowDropDown,
  ArrowDropDownCircle,
  PriceCheck,
  CreditScore,
  TollOutlined,
  Info,
  Groups,
  PersonSearch,
  HelpOutline,
  Speed,
  PanToolAlt,
  TrendingFlat,
  PeopleAltOutlined,
  CampaignOutlined,
  FiberManualRecord,
  AccessTimeFilledOutlined,
  AccessTimeFilled,
  Co2,
  Public,
  Insights,
  InsightsOutlined,
  ArrowUpward,
  ArrowDownward,
  HorizontalRule,
  TrendingUpOutlined,
  TrendingDownOutlined,
  TrendingFlatOutlined,
  FilterCenterFocus,
  TipsAndUpdates,
  NavigateNext,
  ReportProblem,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
  Text,
  LineChart,
  Legend,
  Line,
  ComposedChart,
  Treemap,
  Rectangle,
  ScatterChart,
  Scatter,
  ZAxis,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Tooltip as MUITooltip } from "@mui/material";
import { styled } from "@mui/system";
// import { TrendingDown, Minus, PieChart, ChevronDown ,X} from 'lucide-react';
import { TypeAnimation } from "react-type-animation";
import bggenai from "../../assets/bggenai.png";
import bgsd from "../../assets/bgsd.png";
import ailoader from "../../assets/ailoader.gif";
import bgSS from "../../assets/bgSS.png";
import { IoIosLocate } from "react-icons/io";
import { FaHome, FaUpload, FaChartBar, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { withTranslation } from "react-google-multi-lang";
// import { translateBulk } from "./translationService";
import { TbTopologyStar3 } from "react-icons/tb";
import { SidebarFixed } from "./ProSidebar";
import {
  InfoCard,
  SpendContent,
  CategoriesContent,
  SupplierContent,
} from "./SpendOverview";
import { HistogramChart } from "./CategoryChart";
import { InsightContainer } from "./CategoryInsights";
import { CarbonChart } from "./CarbonEmissions";
import { MarketTable } from "./MarketOverview";
import { SupplierChartFunc } from "./SupplierChart";
import { SupplierInsightContainer } from "./SupplierTable";
import { ALLOWED_FILE_FORMATS, BACKEND_BASE_URL } from "../../constants";
import { CommodityPriceChart } from "./CommodityPriceChart";
import Logo from "../../assets/Logo.svg";

const SpendDashboard = () => {
  const [file, setFile] = useState(null);
  const [dashboardData, setDashboardData] = useState({
    spend_summary: {
      overall_spend: "",
      yoy_increase_spend: null,
      most_recent_year: "",
      second_most_recent_year: "",
      currency_value: "",
    },
    category_summary: {
      num_l1_categories: 0,
      num_l2_categories: 0,
      contribution_statement: "",
    },
    supplier_summary: {
      total_suppliers: 0,
      top_80_percent_supplier_count: 0,
      yoy_increase_suppliers: null,
      most_recent_year: "",
      second_most_recent_year: "",
    },
  });

  // const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showNewsOverlay, setShowNewsOverlay] = useState(false);
  const [categoryChartData, setCategoryChartData] = useState([]);
  const [categoryChartDataL1, setCategoryChartDataL1] = useState([]);
  const [spendChartData, setSpendChartData] = useState([]);
  const [supplierChartData, setSupplierChartData] = useState(null);
  const [supplierTableData, setSupplierTableData] = useState(null);
  const [categoryInsights, setCategoryInsights] = useState(null);
  const [supplierInsights, setSupplierInsights] = useState(null);
  const [spendInsights, setSpendInsights] = useState([]);
  const [open, setOpen] = useState(false);
  const [clientInsights, setClientInsights] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState("upload spend");
  const [hasAnimated, setHasAnimated] = useState({});
  const [role, setRole] = useState("Head of Direct Procurement");
  const [purpose, setPurpose] = useState(
    "I am looking to negotiate with Suppliers"
  );
  const [showUpload, setShowUpload] = useState(false);
  const [loadingSection, setLoadingSection] = useState(null);
  // const [costStructureData, setCostStructureData] = useState([]);
  // const [utilityData, seUtilityData] = useState([])
  // const [laborData, setLaborData] = useState([])
  const [progress, setProgress] = useState({
    companyDetails: false,
    spendPatterns: false,
    spendSummary: false,
    categorySummary: false,
    supplierSummary: false,
    categorySpend: false,
    topCategories: false,
  });
  const [isTranslated, setIsTranslated] = useState(false);
  const [clientNames, setClientNames] = useState([]);

  // NEW STATES
  const [spendSummary, setSpendSummary] = useState({
    spend_summary: {
      overall_spend: "",
      yoy_increase_spend: null,
      most_recent_year: "",
      second_most_recent_year: "",
      currency_value: "",
    },
  });

  const [categorySummary, setCategorySummary] = useState({
    category_summary: {
      num_l1_categories: null,
      num_l2_categories: null,
      contribution_statement: "",
    },
  });

  const [supplierSummary, setSupplierSummary] = useState({
    supplier_summary: {
      total_suppliers: null,
      top_80_percent_supplier_count: null,
      yoy_increase_suppliers: null,
      most_recent_year: "",
      second_most_recent_year: "",
    },
  });
  const [topCategories, setTopCategories] = useState([]);
  const [topContributors, setTopContributors] = useState({});
  const [costStructure, setCostStructure] = useState({});
  const [demandandSupply, setDemandandSupply] = useState({});
  const [marketSize, setMarketSize] = useState({});
  const [topSuppliers, setTopSuppliers] = useState({});
  const [categoryNews, setCategoryNews] = useState({});
  const [categoryMessages, setCategoryMessages] = useState({});
  const [categorySummaryCS, setCategorySummaryCS] = useState({});
  const [top3CategoriesSup, setTop3CategoriesSup] = useState([]);
  const [topsuppercat, setTopsuppercat] = useState({});
  const [supDandP, setSupDandP] = useState({});
  const [supCustInfo, setSupCustInfo] = useState({});
  const [supplierNews, setSupplierNews] = useState([]);
  const [riskScore, setRiskScore] = useState({});
  const [scoreCommentary, setScoreCommentary] = useState([]);
  const [carbonEmission, setCarbonEmission] = useState({});
  const [revenue, setRevenue] = useState({});
  const [currency, setCurrency] = useState({});
  const [supplierContribution, setSupplierContribution] = useState("");

  const [top10categories, setTop10Categories] = useState([]);
  const [categoryNewsPart1, setCategoryNewsPart1] = useState({});
  const [categoryNewsPart2, setCategoryNewsPart2] = useState({});
  const [categoryNewsPart3, setCategoryNewsPart3] = useState({});
  const [categoryNewsPart4, setCategoryNewsPart4] = useState({});

  const [messagesPart1, setMessagesPart1] = useState({});
  const [messagesPart2, setMessagesPart2] = useState({});
  const [messagesPart3, setMessagesPart3] = useState({});
  const [messagesPart4, setMessagesPart4] = useState({});
  const [top10categoriesForecast, setTop10categoriesForecast] = useState({});

  const [categoryPart1Response, setCategoryPart1Response] = useState({});
  const [carbonTable, setCarbonTable] = useState({});
  const [forecast, setForecast] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [largestSpendSubcategory, setLargestSpendSubcategory] = useState("");
  const [seriesData, setSeriesData] = useState([]);
  const [selectedSeriesCode, setSelectedSeriesCode] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  //  FOR KPDB
  const [kpdbData, setKpdbData] = useState({});
  const [taxonomyFile, setTaxonomyFile] = useState(null);

  const [supplierProData, setSupplierProData] = useState("");
  const [costStructureMovements, setCostStructureMovements] = useState([]);

  const sectionMapping = {
    "Spend Overview": "Company Details",
    Category: "Category Spend Summary",
    Carbon: "Carbon Emissions",
    Market: "Market Overview",
    Supplier: "Supplier Overview",
  };
  const [currentSection, setCurrentSection] = useState(
    sectionMapping["Spend Overview"]
  );

  const handleBreadcrumbClick = (event, section) => {
    event.preventDefault();
    setCurrentSection(section);
    const element = document.getElementById(section);
    if (element) {
      const yOffset = -115; // Adjust this value based on the combined height of AppBar and Breadcrumbs
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
    @media print {
      .print-white {
        color: white !important;
      }
    }
  `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style); // Cleanup when component unmounts
    };
  }, []);

  const handleNext = () => {
    setShowUpload(true);
  };

  const handleSwitchToAnalytics = () => {
    setActiveSection("analytics");
    setProgress({
      companyDetails: false,
      spendPatterns: false,
      spendSummary: false,
      categorySummary: false,
      supplierSummary: false,
      categorySpend: false,
      topCategories: false,
    });
  };

  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.name.endsWith(".csv")) {
      setFile(uploadedFile);
      const formData = new FormData();
      formData.append("file", uploadedFile);

      setClientInsights("");
      setDashboardData({
        spend_summary: {
          overall_spend: "",
          yoy_increase_spend: null,
          most_recent_year: "",
          second_most_recent_year: "",
          currency_value: "",
        },
        category_summary: {
          num_l1_categories: 0,
          num_l2_categories: 0,
          contribution_statement: "",
        },
        supplier_summary: {
          total_suppliers: 0,
          top_80_percent_supplier_count: 0,
          yoy_increase_suppliers: null,
          most_recent_year: "",
          second_most_recent_year: "",
        },
      });
      setCategoryInsights(null);
      setCategoryChartData([]);
      setCategoryChartDataL1([]);
      setSupplierChartData(null);
      setSupplierTableData(null);
      setSupplierInsights(null);
      setSpendChartData([]);
      setLoading(true);
      setRiskScore();
      setScoreCommentary([]);
      setRevenue({});
      setCurrency({});
      setSupplierContribution("");

      // new states

      setSpendSummary({
        spend_summary: {
          overall_spend: "",
          yoy_increase_spend: null,
          most_recent_year: "",
          second_most_recent_year: "",
          currency_value: "",
        },
      });
      setCategorySummary({
        category_summary: {
          num_l1_categories: 0,
          num_l2_categories: 0,
          contribution_statement: "",
        },
      });
      setSupplierSummary({
        supplier_summary: {
          total_suppliers: 0,
          top_80_percent_supplier_count: 0,
          yoy_increase_suppliers: null,
          most_recent_year: "",
          second_most_recent_year: "",
        },
      });
      setTopCategories([]);
      setTopContributors({});
      setCostStructure({});
      setDemandandSupply({});
      setMarketSize({});
      setTopSuppliers({});
      setCategoryNews({});
      setCategoryMessages({});
      setCategorySummaryCS({});
      setTop3CategoriesSup([]);
      setTopsuppercat({});
      setSupDandP({});
      setSupCustInfo({});
      setSupplierNews([]);
      setRiskScore({});
      setScoreCommentary({});
      setCarbonEmission({});
      setTop10Categories([]);
      setCategoryNewsPart1({});
      setCategoryNewsPart2({});
      setCategoryNewsPart3({});
      setCategoryNewsPart4({});
      setMessagesPart1({});
      setMessagesPart2({});
      setMessagesPart3({});
      setMessagesPart4({});
      setTop10categoriesForecast({});

      setCategoryPart1Response({});
      setCarbonTable({});
      setForecast({});
      setErrorMessage(null);
      setLargestSpendSubcategory("");
      setSeriesData([]);
      setSelectedSeriesCode("");
      setFilteredResults([]);
      setKpdbData({});

      try {
        setSupplierProData("");
        setCostStructureMovements([]);
        setErrorMessage(null); // Clear any previous error messages
        setProgress((prev) => ({ ...prev, companyDetails: true }));
        const uploadResponse = await axios.post(
          `${BACKEND_BASE_URL}/upload_SD`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        setProgress((prev) => ({ ...prev, spendPatterns: true }));
        const filename = uploadResponse.data.filename;

        const clientNamesResponseQ = axios.get(
          `${BACKEND_BASE_URL}/get_client_names?filename=${filename}`
        );

        const clientInsightsResponseQ = axios.get(
          `${BACKEND_BASE_URL}/get_client_insights?filename=${filename}`
        );

        const [clientNamesResponse, clientInsightsResponse] = [
          await clientNamesResponseQ,
          await clientInsightsResponseQ,
        ];

        setClientNames(clientNamesResponse.data);
        setClientInsights(clientInsightsResponse.data);
        setActiveSection("analytics");

        axios
          .get(`${BACKEND_BASE_URL}/get_spend_summary?filename=${filename}`)
          .then((response) => {
            setSpendSummary(response.data);
          });

        axios
          .get(`${BACKEND_BASE_URL}/get_category_summary?filename=${filename}`)
          .then((response) => {
            setCategorySummary(response.data);
          });

        axios
          .get(`${BACKEND_BASE_URL}/get_supplier_summary?filename=${filename}`)
          .then((response) => {
            setSupplierSummary(response.data);
          });

        axios
          .get(`${BACKEND_BASE_URL}/get_category_chart?filename=${filename}`)
          .then((response) => {
            setCategoryChartData(response.data);
          });

        axios
          .get(`${BACKEND_BASE_URL}/get_category_chart_l1?filename=${filename}`)
          .then((response) => {
            setCategoryChartDataL1(response.data);
          });

        axios
          .get(
            `${BACKEND_BASE_URL}/get_category_insights_getTop3categories_CategorySpend_and_TopContributors?filename=${filename}`
          )
          .then((response) => {
            const {
              top3Categories,
              translated_CategorySpend_and_TopContributors_json,
            } = response.data;
            setTopCategories(top3Categories);
            setTopContributors(
              translated_CategorySpend_and_TopContributors_json
            );
          });

        axios
          .get(
            `${BACKEND_BASE_URL}/get_category_insights_CostStructure?filename=${filename}`
          )
          .then((response) => {
            setCostStructure(response.data);
          });

        axios
          .get(
            `${BACKEND_BASE_URL}/get_category_insights_TopCategorySuppliers?filename=${filename}`
          )
          .then((response) => {
            setTopSuppliers(response.data);
          });

        axios
          .get(`${BACKEND_BASE_URL}/get_filter_series?filename=${filename}`)
          .then((response) => {
            setSeriesData(response.data);
            setFilteredResults(response.data);
            setCostStructureMovements(response.data.cost_structure_movements);
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${BACKEND_BASE_URL}/carbon_table?filename=${filename}`)
          .then((response) => {
            setCarbonTable(response.data);
          });

        axios
          .get(
            `${BACKEND_BASE_URL}/get_top10_category_news_cat1?filename=${filename}`
          )
          .then((response) => {
            setCategoryPart1Response(response.data);
          });

        axios
          .get(`${BACKEND_BASE_URL}/get_supplier_chart?filename=${filename}`)
          .then((response) => {
            setSupplierChartData(response.data);
          });

        axios
          .get(
            `${BACKEND_BASE_URL}/get_supplier_insights_SupplierDetailsandProducts?filename=${filename}`
          )
          .then((response) => {
            const {
              translated_SupplierDetailsandProducts_json,
              top3SuppliersPerCategory,
            } = response.data;
            setTop3CategoriesSup(top3SuppliersPerCategory);
            setSupDandP(translated_SupplierDetailsandProducts_json);
          });

        axios
          .get(`${BACKEND_BASE_URL}/dnb_data?filename=${filename}`)
          .then((dnbresponse) => {
            const { supplier_risk_data, revenues } = dnbresponse.data;
            const dataRisk = dnbresponse.data.supplier_risk_data;
            // Initialize variables for risk scores and score commentary
            const fetchedRiskScores = {};
            const fetchedScoreCommentary = {};

            // Populate state variables with data
            for (const [
              supplier,
              { raw_score, score_commentary },
            ] of Object.entries(dataRisk)) {
              fetchedRiskScores[supplier] = raw_score;
              fetchedScoreCommentary[supplier] = score_commentary;
            }

            setRiskScore(fetchedRiskScores);
            setScoreCommentary(fetchedScoreCommentary);

            const dataRevenue = dnbresponse.data.supplier_revenue_data;
            const fetchedRevenue = {};
            const fetchedCurrency = {};

            for (const [supplier, { revenues }] of Object.entries(
              dataRevenue
            )) {
              fetchedRevenue[supplier] = revenues;
              // fetchedCurrency[supplier] = currency;
            }

            setRevenue(fetchedRevenue);
            // setCurrency(fetchedCurrency)
            console.log(fetchedRevenue);
            console.log(fetchedScoreCommentary);
          });

        axios
          .get(`${BACKEND_BASE_URL}/get_supplier_match?filename=${filename}`)
          .then((response) => {
            setSupplierProData(response.data);
          });

        axios
          .get(
            `${BACKEND_BASE_URL}/get_top10_supplier_kpdbData?filename=${filename}`
          )
          .then((response) => {
            setKpdbData(response.data);
          });

        axios
          .get(`${BACKEND_BASE_URL}/get_category_news?filename=${filename}`)
          .then((categoryNewsResponse) => {
            setCategoryNews(categoryNewsResponse.data.news);
            // setCategoryMessages(categoryNewsResponse.data.messages);
            // setCategorySummaryCS(categoryNewsResponse.data.cost_structure_summary);
            console.log(
              "category cs summary",
              categoryNewsResponse.data.cost_structure_summary
            );
            console.log("Full news response:", categoryNewsResponse.data);
          });

        axios
          .get(`${BACKEND_BASE_URL}/get_supplier_news?filename=${filename}`)
          .then((supplierNewsResponse) => {
            setSupplierNews(supplierNewsResponse.data);
          });

        await axios
          .get(`${BACKEND_BASE_URL}/carbon_emissions?filename=${filename}`)
          .then((response) => {
            const data = response.data;
            // Extracting the main keys and total_emissions in the frontend
            const extractedData = Object.entries(data).map(([key, value]) => ({
              name: key,
              order_amount_sum: value.order_amount_sum,
              total_emissions: value.total_emissions,
            }));

            setCarbonEmission(extractedData);
          });
      } catch (error) {
        console.error("Error processing file:", error);
        // alert("Error processing file. Please try again.");
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          if (error.response.data.error === "Missing required columns") {
            const missingColumns =
              error.response.data.missing_columns.join(", ");
            setErrorMessage(`Missing required columns - ${missingColumns}`);
          } else {
            setErrorMessage(error.response.data.error);
          }
        } else {
          setErrorMessage("An unexpected error occurred. Please try again.");
        }
      } finally {
        // Clear states or perform any cleanup actions
        // setSelectedFile(null);
        // setFile(null);
        setLoading(false);
      }
    } else {
      alert("Please upload a CSV file");
    }
  };

  const [chartData, setChartData] = useState([]);

  // Handling selection
  const handleSeriesCodeChange = (event) => {
    const seriesCode = event.target.value;
    setSelectedSeriesCode(seriesCode);

    if (seriesCode) {
      fetchMintecSeriesData(seriesCode);
    }
  };

  const fetchMintecSeriesData = async (seriesCode) => {
    try {
      const response = await fetch(
        `${BACKEND_BASE_URL}/get_mintec_series_data?series_code=${seriesCode}`
      );
      const data = await response.json();

      // Verify the structure of the data before setting the state
      console.log("data is", data); // This will log the entire response
      console.log("Points in data are", data.content.points); // Check the points array specifically

      // Now, set the chart data after making sure it's correct
      if (Array.isArray(data.content.points)) {
        setChartData(data.content.points);
      } else {
        console.error("Data points is not an array:", data.content.points);
      }
    } catch (error) {
      console.error("Error fetching Mintec series data:", error);
    }
  };
  // useEffect(() => {
  //   if (chartData.length > 0) {
  //     console.log('Updated chart data is', chartData);
  //   }
  // }, [chartData]);
  const handleFileChange = (event) => {
    setErrorMessage(null); // Clear any previous error messages
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDownloadSampleFile = () => {
    window.location.href =
      "https://kpsgptstorageaccount.blob.core.windows.net/rapidinsights/SD/Sample.csv";
    // axios({
    //   url: "https://kpsgptstorageaccount.blob.core.windows.net/rapidinsights/SD/Dawn_Foods.csv", // Your Flask route
    //   method: "GET",
    //   responseType: "blob", // Important for downloading files
    // })
    //   .then((response) => {
    //     // Create a URL for the file
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "Sample File - Magic Cube.csv"); // Specify the file name
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading the file:", error);
    //   });
  };

  // Assuming you have a form, prevent the default form submission behavior
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        if (errorMessage === "File Uncategorized") {
          console.log("File Uncategorized: Setting up taxonomy file");

          // Set the taxonomy file only (don't overwrite selectedFile)
          setTaxonomyFile(acceptedFiles[0]);
          console.log("Taxonomy file set", acceptedFiles[0]);

          setErrorMessage(null);
        } else {
          // Set the selected file only if it's a regular file upload
          setSelectedFile(acceptedFiles[0]);
        }
        setOpen(false);
      }
    },
    [errorMessage, setOpen]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: [".csv"],
  });

  const CustomTooltipMarket = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, order_amount_sum, price_change_percentage } =
        payload[0].payload;
      const formatSpend = (amount) => {
        const currencySymbol =
          spendSummary.spend_summary.currency_value === "EUR"
            ? "€"
            : spendSummary.spend_summary.currency_value === "USD"
            ? "$"
            : spendSummary.spend_summary.currency_value === "GBP"
            ? "£"
            : spendSummary.spend_summary.currency_value === "AUD"
            ? "A$"
            : spendSummary.spend_summary.currency_value;

        if (amount >= 1e9) {
          return `${currencySymbol}${Math.round(amount / 1e9)}B`;
        } else if (amount >= 1e6) {
          return `${currencySymbol}${Math.round(amount / 1e6)}M`;
        } else {
          return `${currencySymbol}${Math.round(amount)}`;
        }
      };
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#FFFFFF0F",
            padding: "16px",
            border: "1px solid #FFFFFF0F",
            borderRadius: "6px",
          }}
        >
          <p>
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF99",
              }}
            >
              <b>Category:</b>
            </span>{" "}
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF",
              }}
            >
              {name}
            </span>
          </p>
          <p>
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF99",
              }}
            >
              Spend:
            </span>{" "}
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF",
              }}
            >
              {formatSpend(order_amount_sum)}
            </span>
          </p>
          <p>
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF99",
              }}
            >
              Net Change:
            </span>{" "}
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF",
              }}
            >
              {price_change_percentage.toFixed(0)}%
            </span>
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomLabel = ({ category, viewBox }) => {
    return (
      <text
        x={viewBox.x}
        y={viewBox.y + 20} // Position the label below the bubble
        textAnchor="middle"
        fill="white"
        fontSize="12"
        fontStyle="Roboto"
      >
        {category}
      </text>
    );
  };

  // import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, Legend, Label, ReferenceLine, ReferenceArea } from 'recharts';

  const MarketChart = ({ marketChart }) => {
    const formatNumber = (value) => {
      if (value >= 1e9) {
        return (value / 1e9).toFixed(1) + "B";
      } else if (value >= 1e6) {
        return (value / 1e6).toFixed(1) + "M";
      } else {
        return value;
      }
    };

    const formatPercentage = (value) => `${Math.round(value)}%`; // Rounding off the percentage

    const dummyMarketChart = [
      {
        name: "Sugar",
        price_change_percentage: -42.86,
        order_amount_sum: 87000000,
        co_ef: "17.04%",
      },
      {
        name: "Starch",
        price_change_percentage: -8.33,
        order_amount_sum: 65000000,
        co_ef: "4.64%",
      },
      {
        name: "Fruit & vegetables",
        price_change_percentage: 1.75,
        order_amount_sum: 46000000,
        co_ef: "1.23%",
      },
      {
        name: "Dairy",
        price_change_percentage: 4.04,
        order_amount_sum: 43000000,
        co_ef: "4.22%",
      },
      {
        name: "Fats & Oils",
        price_change_percentage: 25.55,
        order_amount_sum: 41000000,
        co_ef: "6.15%",
      },
      {
        name: "Flours",
        price_change_percentage: -16.11,
        order_amount_sum: 39000000,
        co_ef: "7.91%",
      },
      {
        name: "Sweeteners",
        price_change_percentage: -30.77,
        order_amount_sum: 35000000,
        co_ef: "13.72%",
      },
      {
        name: "Hydrocolloid",
        price_change_percentage: -33.98,
        order_amount_sum: 29000000,
        co_ef: "17.63%",
      },
      {
        name: "Eggs",
        price_change_percentage: 4.58,
        order_amount_sum: 28000000,
        co_ef: "2.61%",
      },
      {
        name: "Chocolate Couvert",
        price_change_percentage: 129.55,
        order_amount_sum: 26000000,
        co_ef: "38.35%",
      },
    ];

    const {
      maxPriceChange,
      minPriceChange,
      maxSpend,
      maxEmissions,
      top3Categories,
    } = useMemo(() => {
      const sortedBySpend = [...dummyMarketChart].sort(
        (a, b) => b.order_amount_sum - a.order_amount_sum
      );
      return {
        maxPriceChange: Math.max(
          ...dummyMarketChart.map((item) => item.price_change_percentage)
        ),
        minPriceChange: Math.min(
          ...dummyMarketChart.map((item) => item.price_change_percentage)
        ),
        maxSpend: Math.max(
          ...dummyMarketChart.map((item) => item.order_amount_sum)
        ),
        maxEmissions: Math.max(
          ...dummyMarketChart.map((item) => item.total_emissions)
        ),
        top3Categories: sortedBySpend.slice(0, 3).map((item) => item.name),
      };
    }, []);

    const axisRange = Math.max(
      Math.abs(maxPriceChange),
      Math.abs(minPriceChange)
    );

    const getVolatilityCategory = (coEf) => {
      const value = parseFloat(coEf);
      if (value < 5) return "low";
      if (value < 10) return "medium";
      return "high";
    };

    const getColorByVolatility = (category) => {
      switch (category) {
        case "low":
          return "#CA698F"; // The end color of the gradient
        case "medium":
          return "#C26CBA"; // A mid-point between the start and end colors
        case "high":
          return "#BF6FE5"; // The start color of the gradient
        default:
          return "#C26CBA"; // A mid-point as fallback
      }
    };

    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF08",
            width: "100%",
            padding: "16px",
            gap: "16px",
            border: "1px solid #FFFFFF0F",
            borderRadius: "10px",
            p: 2,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FilterCenterFocus
              mr={1}
              sx={{ color: "white", marginRight: 1, fontSize: "20px" }}
            />
            <Typography
              // variant="h6"
              color="white"
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "18px",
                color: "white",
                marginBottom: "14px",
              }}
            >
              Focused categories based on Market Trends
            </Typography>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart margin={{ top: 20, right: 30, left: 40, bottom: 25 }}>
              <XAxis
                type="number"
                dataKey="price_change_percentage"
                name="% Change in Market Price"
                tickFormatter={formatPercentage} // Round off the percentage
                domain={[-axisRange, axisRange]}
                tick={{
                  fill: "white",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontStyle: "Roboto",
                }}
                label={{
                  value: "% Change in Market Price (last 12 months)",
                  position: "insideBottom",
                  offset: -10,
                  fill: "white",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontStyle: "Roboto",
                }}
              />
              <YAxis
                type="number"
                dataKey="order_amount_sum"
                name="Order Amount Sum"
                tickFormatter={formatNumber}
                domain={[0, maxSpend]}
                tick={{
                  fill: "white",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontStyle: "Roboto",
                }}
              >
                <Label
                  value="Spend (€)"
                  angle={-90}
                  position="insideLeft"
                  style={{
                    fill: "white",
                    fontWeight: 400,
                    fontSize: "12px",
                    fontStyle: "Roboto",
                  }}
                  offset={-20}
                />
              </YAxis>

              {/* Set ZAxis to depend on order_amount_sum */}
              <ZAxis
                type="number"
                dataKey="order_amount_sum"
                range={[100, 1000]}
                name="Order Amount (Bubble Size)"
              />

              <Tooltip
                cursor={{ stroke: "none", fill: "transparent" }}
                content={<CustomTooltipMarket />}
              />
              <Legend
                verticalAlign="top"
                height={36}
                align="left"
                wrapperStyle={{ paddingLeft: "40px" }}
              />

              <ReferenceLine
                x={0}
                stroke="rgba(255,255,255,0.3)"
                strokeDasharray="3 3"
              />
              <ReferenceLine
                y={maxSpend / 2}
                stroke="rgba(255,255,255,0.3)"
                strokeDasharray="3 3"
              />

              <ReferenceArea
                x1={0}
                x2={axisRange}
                y1={maxSpend / 2}
                y2={maxSpend}
                fill="#808080"
                fillOpacity={0.2}
              >
                <Label
                  content={({ viewBox }) => (
                    <text
                      x={viewBox.x + viewBox.width / 2}
                      y={viewBox.y - 20}
                      fill="white"
                      fontSize={12}
                      fontStyle="Roboto"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.x + viewBox.width / 2}
                        dy="-2em"
                        zIndex={10}
                      >
                        Manage risk
                      </tspan>
                      <tspan
                        x={viewBox.x + viewBox.width / 2}
                        dy="1.5em"
                        fontStyle="italic"
                        fontSize="10px"
                        fill="grey"
                      >
                        Secure prices, plan hedges, diversify etc.
                      </tspan>
                    </text>
                  )}
                />
              </ReferenceArea>

              <ReferenceArea
                x1={-axisRange}
                x2={0}
                y1={maxSpend / 2}
                y2={maxSpend}
                fill="#808080"
                fillOpacity={0.2}
              >
                <Label
                  content={({ viewBox }) => (
                    <text
                      x={viewBox.x + viewBox.width / 2}
                      y={viewBox.y - 20}
                      fill="white"
                      fontSize={12}
                      fontStyle="Roboto"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan x={viewBox.x + viewBox.width / 2} dy="-2em">
                        Take advantage
                      </tspan>
                      <tspan
                        x={viewBox.x + viewBox.width / 2}
                        dy="1.5em"
                        fontStyle="italic"
                        fontSize="10px"
                        fill="grey"
                      >
                        Delay commitments, tie to index etc.
                      </tspan>
                    </text>
                  )}
                />
              </ReferenceArea>

              <Scatter
                // name={<span style={{ fontStyle: 'Roboto', fontSize:'14px', color: 'white' }}>Category</span>}
                data={dummyMarketChart}
                shape={(props) => {
                  const { cx, cy, payload } = props;
                  const isTop3 = top3Categories.includes(payload.name);
                  const size = (payload.order_amount_sum / maxSpend) * 30;
                  const volatilityCategory = getVolatilityCategory(
                    payload.co_ef
                  );
                  const color = getColorByVolatility(volatilityCategory);

                  return (
                    <g>
                      <circle cx={cx} cy={cy} r={size} fill={color} />
                      {isTop3 && (
                        <text
                          x={cx}
                          y={cy + size + 15}
                          textAnchor="middle"
                          fill="white"
                          fontSize="12"
                          fontStyle="Roboto"
                        >
                          {payload.name}
                        </text>
                      )}
                    </g>
                  );
                }}
              />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#BF6FE5" />
                  <stop offset="100%" stopColor="#CA698F" />
                </linearGradient>
              </defs>
            </ScatterChart>
          </ResponsiveContainer>
          {/* Color legend */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 0.5 }}>
            {["low", "medium", "high"].map((category) => (
              <Box
                key={category}
                sx={{ display: "flex", alignItems: "center", mr: 3 }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: getColorByVolatility(category),
                    mr: 1,
                    borderRadius: "50%", // This makes the box round
                  }}
                />
                <Typography color="white" fontSize={12} fontStyle={"Roboto"}>
                  {category.charAt(0).toUpperCase() + category.slice(1)}{" "}
                  Volatility (
                  {category === "low"
                    ? "0-5%"
                    : category === "medium"
                    ? "5-10%"
                    : "10%+"}
                  )
                </Typography>
              </Box>
            ))}
          </Box>
          {/* </Box> */}
        </Box>
      </>
    );
  };

  const SlidingContent = ({ slides, loading }) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [slideDirection, setSlideDirection] = useState("left");

    useEffect(() => {
      if (loading) {
        const interval = setInterval(() => {
          setSlideDirection((prev) => (prev === "left" ? "right" : "left"));
          setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 3000);
        return () => clearInterval(interval);
      }
    }, [loading, slides.length]);

    return (
      <Box>
        <Box
          sx={{
            background: "purple",
            padding: "16px",
            borderRadius: "8px",
            maxWidth: "100%",
            height: "60px",
            display: "flex",
            alignItems: "center",
            color: "white",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Slide in={loading} direction={slideDirection} timeout={500}>
            <Box display="flex" alignItems="center">
              {slides[slideIndex].icon}
              <Typography
                variant="subtitle1"
                sx={{ color: "white", marginLeft: "8px" }}
              >
                {slides[slideIndex].text}
              </Typography>
            </Box>
          </Slide>
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          {slides.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: "10px",
                height: "10px",
                backgroundColor: index === slideIndex ? "#A0186A" : "white", // Use the specified color for both active and inactive states
                borderRadius: "50%",
                margin: "0 4px",
              }}
            />
          ))}
        </Box>
      </Box>
    );
  };

  // const SlideTransition = React.forwardRef((props, ref) => {
  //   return <Slide direction={slideDirection} ref={ref} {...props} />;
  // });

  const slides = [
    {
      text: "KPS was founded in 2021!",
      icon: <PersonSearch sx={{ color: "white" }} />,
    },
    {
      text: "KPS team size has grown 5x in just 3 years!",
      icon: <Groups sx={{ color: "white" }} />,
    },
  ];

  const [openModal, setOpenModal] = useState(false);

  const handleGenerate = () => {
    if (selectedFile) {
      setLoading(true);
      setOpenModal(true);
      handleFileUpload({ target: { files: [selectedFile] } });
    }
  };

  const UploadSection = () => {
    // return (
    const [role, setRole] = useState("Head of direct procurement");
    const [currentSlide, setCurrentSlide] = useState(0);

    const didYouKnowTexts = [
      "We established our first overseas office in Germany in 1964",
      "Kearney is now more than 5,700 people strong, with more than 25,000 people in our alumni network",
      "Newsweek identified Kearney as one of America's Greatest Workplaces for Diversity 2023",
      "Kearney is the first consulting firm with SBTi-approved emissions targets, and became carbon neutral in 2010",
      "KPS was founded in 2021 and has grown 5x since its inception",
      "Over 60+ KPS digital asset deployments have been completed in various projects",
      "KPS is expanding beyond sourcing to broader operations and will rebrand as KOS (Kearney Operations Systems)",
    ];
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide(
          (prevSlide) => (prevSlide + 1) % didYouKnowTexts.length
        );
      }, 2000); // Change slide every 3 seconds

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [didYouKnowTexts.length]);

    const handleGenerateTaxonomy = async () => {
      if (!selectedFile || !taxonomyFile) {
        setErrorMessage("Please upload both data and taxonomy files");
        return;
      }

      const formData = new FormData();
      formData.append("dataFile", selectedFile);
      formData.append("taxonomyFile", taxonomyFile);

      try {
        setLoading(true);

        const response = await axios.post(
          `${BACKEND_BASE_URL}//process_taxonomy`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            responseType: "blob", // This ensures the CSV file is returned as a Blob
          }
        );

        // Create a new file object from the response blob
        const csvFile = new File([response.data], "processed_file.csv", {
          type: "text/csv",
        });

        // Call the handleFileUpload function and pass the new file object
        handleFileUpload({ target: { files: [csvFile] } });
      } catch (error) {
        setErrorMessage(
          error.response?.data?.message || "Failed to process files"
        );
      } finally {
        setLoading(false);
      }
    };

    const handleTaxonomyUpload = (file) => {
      // Validate file type if needed
      setTaxonomyFile(file);
      setErrorMessage(null);
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          // width: "210vh",
          padding: "40px",
          zIndex: 10000,
        }}
      >
        {!loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "75vh",
              padding: "20px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "white",
                marginBottom: "30px",
                fontSize: "28px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              <img src={Logo} style={{ width: "200px" }} />
            </Typography>

            <Box
              sx={{
                backgroundColor: "#FFFFFF0F",
                border: "1px solid #FFFFFF1A",
                borderRadius: "16px",
                padding: "24px",
                width: "100%",
                maxWidth: "400px",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "#8b8b8b",
                  marginBottom: "10px",
                  fontSize: "12px",
                }}
              >
                Step 1 of 2
              </Typography>

              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  marginBottom: "30px",
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                {errorMessage === "File Uncategorized" ? (
                  <>
                    <>Uncategorized spend data detected</>
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#FFFFFF99",
                        marginTop: "12px",
                      }}
                    >
                      Looks like the uploaded file is not categorized. Please
                      upload your taxonomy to categorize the spend.
                    </p>
                  </>
                ) : (
                  "Upload your data"
                )}
              </Typography>

              {!selectedFile ? (
                <Box
                  sx={{
                    border: "2px dashed #3f434c",
                    borderRadius: "8px",
                    padding: "30px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <CloudUpload
                    sx={{
                      color: "#8b8b8b",
                      fontSize: 40,
                      marginBottom: "10px",
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    Drag & drop your files here
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#8b8b8b",
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    File format should be {ALLOWED_FILE_FORMATS.join(", ")}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      background: "#FFFFFF0F",
                      border: "1px solid #EAECF01F",
                      borderRadius: "7px",
                      padding: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "7px",
                        padding: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "7px",
                          padding: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          src={require(`../../assets/${
                            selectedFile.type.includes("csv") ? "csv" : "xlsx"
                          }.png`)}
                          alt="Icon"
                          style={{
                            height: "34px",
                          }}
                        />
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box>
                          <Typography variant="body2" sx={{ color: "white" }}>
                            {selectedFile.name}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "#8b8b8b" }}>
                            {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Delete
                      sx={{
                        color: "#8b8b8b",
                        cursor: "pointer",
                        marginTop: "-18px",
                      }}
                      onClick={() => {
                        setSelectedFile(null); // First function
                        setErrorMessage(null);
                      }}
                    />
                  </Box>

                  {taxonomyFile && (
                    <Box
                      sx={{
                        background: "#FFFFFF0F",
                        border: "1px solid #EAECF01F",
                        borderRadius: "7px",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "7px",
                          padding: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "7px",
                            padding: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={require(`../../assets/${
                              selectedFile.type.includes("csv") ? "csv" : "xlsx"
                            }.png`)}
                            alt="Icon"
                            style={{
                              height: "34px",
                            }}
                          />
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box>
                            <Typography variant="body2" sx={{ color: "white" }}>
                              {taxonomyFile.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "#8b8b8b" }}
                            >
                              {(taxonomyFile.size / (1024 * 1024)).toFixed(2)}{" "}
                              MB
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Delete
                        sx={{
                          color: "#8b8b8b",
                          cursor: "pointer",
                          marginTop: "-18px",
                        }}
                        onClick={() => {
                          setTaxonomyFile(null); // First function
                          setErrorMessage(null);
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
              {errorMessage && errorMessage !== "File Uncategorized" && (
                <Box
                  sx={{
                    mt: 2,
                    mb: 2,
                    color: "error.main",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontStyle: "Roboto",
                    }}
                  >
                    <ReportProblem
                      sx={{ fontSize: "14px", marginRight: "2px" }}
                    />{" "}
                    {/* Same font size as the text, and margin between icon and text */}
                    <span>Error</span>
                  </div>
                  <Typography sx={{ fontStyle: "Roboto", fontSize: "14px" }}>
                    {errorMessage}
                  </Typography>
                </Box>
              )}

              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  if (selectedFile && taxonomyFile) {
                    console.log("taxonomy file is", taxonomyFile);
                    handleGenerateTaxonomy(); // New method for handling taxonomy file generation
                  } else if (
                    selectedFile &&
                    !taxonomyFile &&
                    errorMessage === "File Uncategorized"
                  ) {
                    setOpen(true);
                  } else if (selectedFile) {
                    handleGenerate(); // Existing generate method
                  } else {
                    setOpen(true); // Open file upload
                  }
                }}
                startIcon={selectedFile ? <AutoAwesome /> : null}
                sx={{
                  background:
                    "linear-gradient(180deg, #BF6FE5 0%, #CA698F 100%)",
                  borderRadius: "12px",
                  color: "white",
                  marginBottom: "20px",
                  textTransform: "none",
                  height: "46px",
                }}
              >
                {selectedFile &&
                !taxonomyFile &&
                errorMessage === "File Uncategorized"
                  ? "Upload Taxonomy"
                  : selectedFile && taxonomyFile
                  ? "Generate"
                  : selectedFile
                  ? "Generate"
                  : "Upload data"}
              </Button>

              {!selectedFile && (
                <Button
                  startIcon={<FileDownload />}
                  sx={{
                    color: "#8b8b8b",
                    textTransform: "none",
                    width: "100%",
                    justifyContent: "center",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                  onClick={handleDownloadSampleFile} // Attach download handler
                >
                  Download sample file
                </Button>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "76vh",
              padding: "20px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "white",
                marginBottom: "10px",
                fontSize: "28px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              <img src={Logo} style={{ width: "200px" }} />
            </Typography>

            <Box
              sx={{
                backgroundColor: "#FFFFFF0F",
                border: "1px solid #FFFFFF1A",
                borderRadius: "16px",
                height: "460px",
                padding: "24px",
                width: "100%",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  marginBottom: "10px",
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                Generating dashboard
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <CircularProgress
                  sx={{
                    color: "white",
                    marginBottom: "20px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{ color: "white", textAlign: "center" }}
                >
                  Generating your dashboard
                </Typography>
              </Box>

              <Box
                sx={{
                  background: "#FFFFFF0F",
                  border: "1px solid #EAECF01F",
                  borderRadius: "7px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                  height: "60px", // Fixed height for consistent sizing
                }}
              >
                <Info sx={{ color: "#8b8b8b", marginRight: "10px" }} />
                <Box
                  sx={{ position: "relative", width: "120%", height: "100%" }}
                >
                  {didYouKnowTexts.map((text, index) => (
                    <Fade key={index} in={currentSlide === index} timeout={500}>
                      <Typography
                        // variant="body2"
                        sx={{
                          color: "white",
                          position: "absolute",
                          fontSize: "12px",
                          top: 0,
                          left: 0,
                          right: 0,
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          opacity: currentSlide === index ? 1 : 0,
                        }}
                      >
                        {text}
                      </Typography>
                    </Fade>
                  ))}
                </Box>
              </Box>

              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <FiberManualRecord
                  sx={{ color: "white", fontSize: "8px", mx: "2px" }}
                />
                <FiberManualRecord
                  sx={{ color: "#8b8b8b", fontSize: "8px", mx: "2px" }}
                />
              </Box> */}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const AnimatedInfoCard = styled(InfoCard)`
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.5s ease forwards;
    animation-delay: ${(props) => props.delay}ms;

    @keyframes fadeIn {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;

  const PriceChart = ({ chartData }) => {
    const formattedData = chartData.map((item) => ({
      ...item,
      date: new Date(item.date).toLocaleDateString(),
      value: parseFloat(item.value),
    }));

    console.log("Data received in PriceChart:", chartData);

    if (!formattedData || formattedData.length === 0) {
      return <div>No data available</div>;
    }
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={formattedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            dot={{ r: 1 }} // Regular dot radius (e.g., 4)
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };
  const AnalyticsSection = () => {
    const componentRef = useRef(null);
    // const [boxWidth, setBoxWidth] = useState("980px");

    // useEffect(() => {
    //   const timer = setTimeout(() => {
    //     setBoxWidth("1200px");
    //   }, 7000); // 15 seconds

    //   return () => clearTimeout(timer);
    // }, []);
    const LoadingGif = () => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50px",
          width: "auto",
        }}
      >
        <img
          src={ailoader}
          alt="Loading..."
          style={{ width: "30px", height: "30px" }}
        />
      </Box>
    );

    const [loading, setLoading] = useState(true);

    const cards = [
      {
        title: "Overall spend",
        icon: <AccountBalanceWalletOutlined />,
        content: <SpendContent spendSummary={spendSummary} />,
      },
      {
        title: "Categories",
        icon: <GridViewOutlined />,
        content: <CategoriesContent categorySummary={categorySummary} />,
      },
      {
        title: "Suppliers",
        icon: <ApartmentOutlined />,
        content: <SupplierContent supplierSummary={supplierSummary} />,
      },
    ];

    const card = {
      title: "Overall spend",
      icon: <AccountBalanceWalletOutlined />,
      content: <SpendContent spendSummary={spendSummary} />,
    };

    const card1 = {
      title: "Categories",
      icon: <GridViewOutlined />,
      content: <CategoriesContent categorySummary={categorySummary} />,
    };

    const card2 = {
      title: "Suppliers",
      icon: <ApartmentOutlined />,
      content: <SupplierContent supplierSummary={supplierSummary} />,
    };

    console.log("client name", clientNames);
    const { client_names } = clientNames;
    const clientName = client_names[0]; // Directly access the first item

    return (
      <Box
        ref={componentRef}
        sx={{
          display: "flex",
          position: "relative",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            height: "65px",
            opacity: 1,
            borderBottom: "1px solid #2C2D3C",
            // padding: "8px 16px",
            paddingLeft: "35px",
            bgcolor: "#181921", // Keep the background consistent
          }}
        >
          <Toolbar
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between", // Align Typography to the left and Breadcrumbs to the right
              paddingRight: "16px",
            }}
          >
            {/* Typography on the left */}
            <Typography
              variant="h4"
              noWrap
              component="div"
              style={{
                fontStyle: "Roboto",
                fontSize: "18px",
                fontWeight: 600,
                // lineHeight: "14.52px",
                textAlign: "left",
                marginLeft: "2rem",
              }}
            >
              <img src={Logo} style={{ width: "159px", height: "40px" }} />
            </Typography>

            {/* Breadcrumbs on the right */}
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNext fontSize="small" />}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "rgba(255, 255, 255, 0.6)",
                fontStyle: "Roboto",
                fontSize: "14px",
                textDecoration: "none",
                "& .MuiBreadcrumbs-separator": {
                  margin: "0 8px",
                },
              }}
            >
              {Object.keys(sectionMapping).map((section) => (
                <Link
                  key={section}
                  color="inherit"
                  href="#"
                  onClick={(event) =>
                    handleBreadcrumbClick(event, sectionMapping[section])
                  }
                  sx={{
                    textDecoration: "none",
                    fontWeight:
                      currentSection === sectionMapping[section]
                        ? "bold"
                        : "normal",
                    color:
                      currentSection === sectionMapping[section]
                        ? "white"
                        : "rgba(255, 255, 255, 0.6)",
                    "&:hover": {
                      fontWeight: "bold",
                      color: "white",
                    },
                    transition: "all 0.3s ease",
                    background:
                      currentSection === sectionMapping[section]
                        ? "#BE3FF5"
                        : "inherit",
                    padding: "10px",
                    borderRadius: "4px",
                  }}
                >
                  {section}
                </Link>
              ))}
            </Breadcrumbs>
          </Toolbar>
        </AppBar>

        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            // bgcolor: "#181921",
            // backgroundImage: `url(${bggenai})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            // marginTop: "55px",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              padding: "24px 0",
              // bgcolor: "#181921",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              marginTop: "30px",
              width: "100%",
            }}
          >
            {/* Company Details Section */}
            <Box
              id="Company Details"
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontStyle: "Roboto",
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "18px",
                  color: "white",
                }}
              >
                <Info sx={{ mr: 0.5 }} /> Company details
              </Typography>
              <Box sx={{ width: "100%", height: "1px", mb: 2 }} />
              <Box sx={{ width: "100%" }}>
                {clientInsights ? (
                  <Box
                    sx={{
                      mb: 2,
                      display: "flex",
                      background: "#FFFFFF08",
                      color: "white",
                      justifyContent: "space-between",
                      border: "1px solid #2C2D3C",
                      borderRadius: "8px",
                      width: "100%",
                      padding: "16px",
                      fontSize: "14px",
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                  >
                    <Typography
                      sx={{
                        fontStyle: "Roboto",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      {clientInsights}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "240px", // Adjust height as needed
                    }}
                  >
                    <LoadingGif />
                  </Box>
                )}
              </Box>
            </Box>

            {/* Spend Overview Section */}
            <Box
              id="Spend Overview"
              sx={{
                mb: 4,
                mt: 4,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Typography
                sx={{
                  fontStyle: "Roboto",
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "18px",
                  color: "white",
                  gap: "7px",
                }}
              >
                <TollOutlined sx={{ mr: 1 }} />
                Spend overview
              </Typography>
              <Box sx={{ width: "100%", height: "1px", mb: 2 }} />

              <Grid
                container
                spacing={0}
                sx={{ height: "240px" }}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Grid item xs={12} md={4} sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {spendSummary.spend_summary.overall_spend &&
                    categorySummary.category_summary.num_l1_categories &&
                    supplierSummary.supplier_summary.total_suppliers ? (
                      <AnimatedInfoCard
                        delay={1000}
                        icon={card.icon}
                        title={card.title}
                        content={card.content}
                      />
                    ) : (
                      <LoadingGif />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {spendSummary.spend_summary.overall_spend &&
                    categorySummary.category_summary.num_l1_categories &&
                    supplierSummary.supplier_summary.total_suppliers ? (
                      <AnimatedInfoCard
                        delay={2000}
                        icon={card1.icon}
                        title={card1.title}
                        content={card1.content}
                      />
                    ) : (
                      <LoadingGif />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {spendSummary.spend_summary.overall_spend &&
                    categorySummary.category_summary.num_l1_categories &&
                    supplierSummary.supplier_summary.total_suppliers ? (
                      <AnimatedInfoCard
                        delay={3000}
                        icon={card2.icon}
                        title={card2.title}
                        content={card2.content}
                      />
                    ) : (
                      <LoadingGif />
                    )}
                  </Box>
                </Grid>
              </Grid>

              {/* {loading && !dashboardData && <LoadingGif />} */}
            </Box>

            {/* Category Overview Section */}
            <Box
              id="Category Spend Summary"
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontStyle: "Roboto",
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "18px",
                  color: "white",
                }}
              >
                <GridViewOutlined sx={{ mr: 1 }} />
                Category spend summary
              </Typography>
              <Box sx={{ height: "1px", mb: 2, width: "100%" }} />
              <Box
                sx={{
                  backgroundColor: "#FFFFFF08",
                  borderRadius: "8px",
                  padding: 2,
                  width: "100%",
                }}
              >
                {categoryChartData && categoryChartDataL1 && (
                  //  <Box sx={{ backgroundColor: "rgba(129, 129, 129, 0.25)", borderRadius: "30px", padding: 2, width: "100%" }}>
                  <Fade in={true} timeout={1000}>
                    <Box
                      style={{
                        width: "100%",
                        marginBottom: "3rem",
                      }}
                    >
                      <HistogramChart
                        chartData={categoryChartData}
                        categoryChartDataL1={categoryChartDataL1}
                        spendSummary={spendSummary}
                        barColor="lightblue"
                      />
                    </Box>
                  </Fade>
                  //  </Box>
                )}

                {topCategories != "" ? (
                  // <Box sx={{ backgroundColor: "rgba(129, 129, 129, 0.25)", borderRadius: "30px", padding: 2, width: "100%" }}>

                  <InsightContainer
                    title="Insights"
                    topCategories={topCategories}
                    topContributors={topContributors}
                    marketSize={marketSize}
                    costStructure={costStructure}
                    demandandSupply={demandandSupply}
                    topSuppliers={topSuppliers}
                    categoryChartData={categoryChartData}
                    categoryChartDataL1={categoryChartDataL1}
                    clientNames={clientNames}
                    categoryNews={categoryNews}
                  />
                ) : (
                  // </Box>
                  <LoadingGif />
                )}

                <div style={{ marginTop: "2rem" }}>
                  {filteredResults.result &&
                  filteredResults.result.length > 0 ? (
                    <CommodityPriceChart
                      filteredResults={filteredResults.result}
                      costStructureMovements={costStructureMovements}
                    />
                  ) : (
                    <LoadingGif />
                  )}
                </div>
              </Box>
            </Box>

            {/* {Carbon Chart Section} */}
            <Box
              id="Carbon Emissions"
              sx={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                mt: 4,
                position: "relative",
              }}
            >
              <MUITooltip
                title={
                  <Typography sx={{ fontStyle: "italic", fontSize: "14px" }}>
                    Indicates Scope 3 emissions by category
                  </Typography>
                }
                arrow
                placement="right"
                enterDelay={500}
                leaveDelay={200}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontStyle: "Roboto",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "18px",
                    color: "white",
                    display: "inline-flex", // Change to inline-flex
                    alignItems: "center",
                    cursor: "pointer",
                    position: "relative", // Add this
                    zIndex: 1, // Add this
                  }}
                >
                  <Public sx={{ mr: 1 }} />
                  Carbon emissions
                </Typography>
              </MUITooltip>
              <Box sx={{ height: "1px", mb: 2, width: "100%" }} />
              <Box
                sx={{
                  backgroundColor: "#FFFFFF08",
                  borderRadius: "8px",
                  padding: 2,
                  width: "100%",
                }}
              >
                {spendSummary &&
                carbonEmission &&
                Object.keys(carbonEmission).length > 0 ? (
                  <Fade in={true} timeout={1000}>
                    <Box
                      style={{
                        width: "100%",
                        marginBottom: "1rem",
                      }}
                    >
                      <CarbonChart
                        carbonEmission={carbonEmission}
                        carbonTable={carbonTable}
                        spendSummary={spendSummary}
                      />
                    </Box>
                  </Fade>
                ) : (
                  <LoadingGif />
                )}
              </Box>
            </Box>

            {/* {Market Overview Section} */}
            <Box
              id="Market Overview"
              sx={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                mt: 4,
                position: "relative",
              }}
            >
              {/* <MUITooltip
      title={
        <Typography sx={{ fontStyle: 'italic',fontSize: "14px", }}>
          Indicates degree of emissions (in KgCO2) and spend by category
        </Typography>
      }
      arrow
      placement="right"
      enterDelay={500}
      leaveDelay={200}
    > */}
              <Typography
                variant="h6"
                sx={{
                  fontStyle: "Roboto",
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "18px",
                  color: "white",
                  display: "inline-flex", // Change to inline-flex
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative", // Add this
                  zIndex: 1, // Add this
                }}
              >
                <InsightsOutlined sx={{ mr: 1 }} />
                Market overview
              </Typography>
              {/* </MUITooltip> */}
              <Box sx={{ height: "1px", mb: 2, width: "100%" }} />
              <Box
                sx={{
                  backgroundColor: "#FFFFFF08",
                  borderRadius: "8px",
                  padding: 2,
                  width: "100%",
                }}
              >
                {top10categories && (
                  <Fade in={true} timeout={1000}>
                    <Box
                      style={{
                        width: "100%",
                        height: "auto",
                        marginBottom: "1rem",
                      }}
                    >
                      {!categoryPart1Response ||
                      Object.keys(categoryPart1Response).length === 0 ? (
                        <LoadingGif />
                      ) : (
                        <MarketTable
                          categoryResponse={Object.values(
                            categoryPart1Response
                          )}
                          forecast={forecast}
                          spendSummary={spendSummary}
                        />
                      )}
                    </Box>
                  </Fade>
                )}
              </Box>
            </Box>

            {/* Supplier Overview Section */}
            <Box id="Supplier Overview" sx={{ mt: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontStyle: "Roboto",
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "18px",
                  color: "white",
                }}
              >
                <ApartmentOutlined sx={{ mr: 1 }} />
                Supplier overview
              </Typography>
              <Box sx={{ width: "100%", height: "1px", mb: 2 }} />
              <Box
                sx={{
                  backgroundColor: "#FFFFFF08",
                  borderRadius: "8px",
                  padding: 2,
                }}
              >
                {supplierChartData && (
                  <Fade in={true} timeout={1000}>
                    <Box
                      style={{
                        width: "100%",
                        marginBottom: "2rem",
                      }}
                    >
                      <SupplierChartFunc
                        supplierChartData={supplierChartData}
                        spendSummary={spendSummary}
                      />
                      {/* <SupplierTableFunc supplierTableData={supplierTableData} /> */}
                    </Box>
                  </Fade>
                )}
                {top3CategoriesSup != "" ? (
                  <SupplierInsightContainer
                    title="Insights"
                    top3CategoriesSup={top3CategoriesSup}
                    supDandP={supDandP}
                    supCustInfo={supCustInfo}
                    riskScore={riskScore}
                    scoreCommentary={scoreCommentary}
                    revenue={revenue}
                    supplierNews={supplierNews}
                    spendSummary={spendSummary}
                    supplierProData={supplierProData}
                    kpdbData={kpdbData}
                  />
                ) : (
                  // </Box>
                  <LoadingGif />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleNextUp = () => {
    setActiveSection("upload spend");
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          backgroundImage: `url(${bgsd})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundImage: `url(${bgsd})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
          }}
        >
          {activeSection === "welcome" ? (
            <UploadSection
              // Pass necessary props here
              role={role}
              setRole={setRole}
              purpose={purpose}
              setPurpose={setPurpose}
              handleNext={handleNextUp}
              setSelectedFile={setSelectedFile}
            />
          ) : activeSection === "upload spend" ? (
            <UploadSection
              // Pass necessary props here
              onComplete={() => setActiveSection("insights")}
            />
          ) : (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginTop: "60px" }}>
                <SidebarFixed
                  setActiveSection={setActiveSection}
                  setShowUpload={setShowUpload}
                />
              </div>
              <AnalyticsSection
              // Pass necessary props here
              />
            </div>
          )}
        </Box>
        <Dialog
          open={open}
          onClose={() => !loading && setOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}
          >
            Upload your files
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: "center", mb: 2 }}
            >
              File should be of format .csv
            </Typography>
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #ccc",
                borderRadius: "8px",
                p: 3,
                textAlign: "center",
                cursor: "pointer",
                bgcolor: isDragActive ? "action.hover" : "background.paper",
              }}
            >
              <input {...getInputProps()} />
              <FolderIcon sx={{ fontSize: 48, color: "primary.main", mb: 2 }} />
              <Typography variant="body1" color="text.secondary">
                Drag & Drop your files here
              </Typography>
            </Box>

            {loading && (
              <Box className="w-full mt-4 text-center">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div className="bg-blue-600 h-2.5 rounded-full w-full animate-pulse"></div>
                </div>
                <Typography variant="body2" className="mt-2">
                  Preparing Dashboard...
                </Typography>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </div>
  );
};

export default SpendDashboard;
