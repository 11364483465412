import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { margin, styled } from "@mui/system";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import {
  ArrowDropDownCircle,
  AutoAwesome,
  TrendingDown,
  TrendingDownRounded,
  TrendingUp,
  TrendingUpSharp,
} from "@mui/icons-material";
import ailoader from "../../assets/ailoader.gif";
import gs from "../../assets/gs.png";

const Container = styled("div")({
  backgroundColor: "#FFFFFF08",
  border: "1px solid #FFFFFF0F",
  borderRadius: "10px",
  padding: "20px",
  color: "#fff",
  fontStyle: "Roboto",
});

const LegendWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3, auto)",
  gap: "16px",
  justifyContent: "center",
  marginTop: "16px",
  background: "#FFFFFF0F",
  borderRadius: "4px",
  padding: "8px",
  color: "#fff",
  fontSize: "12px",
  fontStyle: "Roboto",
});

const MovementContainer = styled(Box)({
  backgroundColor: "#FFFFFF0F",
  borderRadius: "10px",
  padding: "16px",
  height: "100%",
  overflowY: "auto",
});

const MovementItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "12px",
  padding: "8px",
  backgroundColor: "#FFFFFF08",
  borderRadius: "6px",
});

export const CommodityPriceChart = ({
  filteredResults,
  costStructureMovements,
}) => {
  console.log("cost structur movements is", costStructureMovements);

  const [selectedCategory, setSelectedCategory] = useState(() => {
    return filteredResults && filteredResults.length > 0
      ? filteredResults[0].category
      : "";
  });
  const LoadingGif = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50px",
        width: "auto",
      }}
    >
      <img
        src={ailoader}
        alt="Loading..."
        style={{ width: "30px", height: "30px" }}
      />
    </Box>
  );
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const formatDate = (dateStr) => {
    try {
      const date = dateStr.includes("/")
        ? new Date(dateStr.split("/").reverse().join("-"))
        : new Date(dateStr);

      if (isNaN(date.getTime())) {
        console.warn("Invalid date:", dateStr);
        return dateStr;
      }

      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear().toString().slice(-2);
      return `${month}-${year}`;
    } catch (error) {
      console.warn("Error formatting date:", dateStr, error);
      return dateStr;
    }
  };

  const renderChart = () => {
    const selectedCategoryData = filteredResults.find(
      (category) => category.category === selectedCategory
    );

    if (!selectedCategoryData) return null;

    const allSeries = selectedCategoryData.series;
    const mainCategoryData = allSeries.find(
      (series) => series.type === "main_category"
    );
    const costMaterialData = allSeries.filter(
      (series) => series.type === "cost_structure"
    );

    const dateMap = new Map();

    if (mainCategoryData && mainCategoryData.series_data?.content?.points) {
      mainCategoryData.series_data.content.points.forEach((point) => {
        dateMap.set(point.date, {
          date: formatDate(point.date),
          [mainCategoryData["Series Name"]]: point.value,
        });
      });
    }

    costMaterialData.forEach((costMaterial) => {
      if (costMaterial.series_data?.content?.points) {
        costMaterial.series_data.content.points.forEach((point) => {
          const existingPoint = dateMap.get(point.date) || {
            date: formatDate(point.date),
          };
          existingPoint[costMaterial["Series Name"]] = point.value;
          dateMap.set(point.date, existingPoint);
        });
      }
    });

    let chartData = Array.from(dateMap.values())
      .map((item) => {
        const [month, year] = item.date.split("-");
        const monthIndex = new Date(`${month} 1`).getMonth();
        const fullDate = new Date(parseInt(`20${year}`), monthIndex, 1);
        return { ...item, fullDate };
      })
      .sort((a, b) => a.fullDate - b.fullDate)
      .map(({ fullDate, ...rest }) => rest);

    // Smoothing function
    const smoothData = (data, seriesName, smoothingFactor = 0.3) => {
      return data.map((point, index) => {
        if (index === 0) return point;
        return {
          ...point,
          [seriesName]:
            point[seriesName] * smoothingFactor +
            data[index - 1][seriesName] * (1 - smoothingFactor),
        };
      });
    };

    // Apply smoothing to each series
    chartData = chartData.map((dataPoint) => {
      const smoothedPoint = { ...dataPoint };

      // Smooth main category if it exists
      if (mainCategoryData && mainCategoryData["Series Name"]) {
        const seriesName = mainCategoryData["Series Name"];
        if (smoothedPoint[seriesName]) {
          smoothedPoint[seriesName] = smoothData(chartData, seriesName).find(
            (p) => p.date === dataPoint.date
          )[seriesName];
        }
      }

      // Smooth cost material series
      costMaterialData.forEach((costMaterial) => {
        const seriesName = costMaterial["Series Name"];
        if (smoothedPoint[seriesName]) {
          smoothedPoint[seriesName] = smoothData(chartData, seriesName).find(
            (p) => p.date === dataPoint.date
          )[seriesName];
        }
      });

      return smoothedPoint;
    });

    const colors = [
      "#FFC107",
      "#FF5722",
      "#4CAF50",
      "#03A9F4",
      "#E91E63",
      "#9C27B0",
      "#673AB7",
      "#00BCD4",
      "#8BC34A",
      "#FF9800",
    ];

    return (
      <ResponsiveContainer width="100%" height={421}>
        <LineChart data={chartData}>
          <XAxis
            dataKey="date"
            axisLine={{ stroke: "#FFFFFF", strokeWidth: 1 }} // Add this line
            // tickLine={false}
            tick={{ fill: "#FFFFFF" }}
            style={{ fontSize: "12px", fontStyle: "Roboto" }}
            padding={{ left: 30 }}
            interval="preserveStartEnd"
            allowDuplicatedCategory={false}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#FFFFFF" }}
            style={{ fontSize: "12px", fontStyle: "Roboto" }}
            label={{
              value: "Price Trend",
              angle: -90,
              position: "insideLeft",
              style: {
                fill: "#FFFFFF",
                fontSize: "12px",
                fontStyle: "Roboto",
                textAnchor: "middle",
              },
            }}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "#FFFFFF08",
              color: "#fff",
              fontSize: "12px",
              fontStyle: "Roboto",
              border: "1px solid #FFFFFF0F",
            }}
            cursor={{ stroke: "#555", strokeWidth: 1 }}
            formatter={(value, name) => [Number(value.toFixed(2)), name]} // Modified line
          />
          <Legend
            content={({ payload }) => (
              <LegendWrapper>
                {payload.map((entry, index) => (
                  <div
                    key={`item-${index}`}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: entry.color,
                        marginRight: "8px",
                        borderRadius: "50%",
                      }}
                    />
                    <span>{entry.value}</span>
                  </div>
                ))}
              </LegendWrapper>
            )}
          />

          {mainCategoryData &&
            mainCategoryData.series_data?.content?.points && (
              <Line
                type="basis" // Changed to "basis" for smoother lines
                dataKey={mainCategoryData["Series Name"]}
                stroke={colors[0]}
                name={`${mainCategoryData["Series Name"]} (${
                  mainCategoryData.series_data?.Unit || ""
                })`}
                dot={false}
                strokeWidth={2}
              />
            )}

          {costMaterialData.map((costMaterial, index) =>
            costMaterial.series_data?.content?.points ? (
              <Line
                key={costMaterial["Series Code"]}
                type="basis" // Changed to "basis" for smoother lines
                dataKey={costMaterial["Series Name"]}
                stroke={colors[(index + 1) % colors.length]}
                name={`${costMaterial["Series Name"]} (${
                  costMaterial.series_data?.Unit || ""
                })`}
                dot={false}
                strokeWidth={2}
              />
            ) : null
          )}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const renderMovements = () => {
    // Filter movements for the selected category
    const categoryMovements = costStructureMovements.filter(
      (movement) => movement.category === selectedCategory
    );

    // Calculate simple average if there are multiple cost_structure_type
    const averageMovement =
      categoryMovements.length > 1
        ? categoryMovements.reduce(
            (sum, movement) => sum + movement.movement,
            0
          ) / categoryMovements.length
        : null;

    return (
      <MovementContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            src={gs}
            style={{ width: "20px", height: "20px", marginRight: "5px" }}
          />
          <div>
            <Typography
              color="white"
              sx={{
                fontSize: "14px",
                fontStyle: "Roboto",
                paragraph: "30px",
                marginBottom: "14px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {/* {averageMovement!==null?'Key cost driver movements':'Category movement'} */}
              Key commodity price movements
              <br />
              <Typography
                color="grey"
                sx={{
                  fontSize: "12px",
                  fontStyle: "Roboto",
                  paragraph: "30px",
                  marginBottom: "14px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                over past 12 months
              </Typography>{" "}
            </Typography>
          </div>
        </div>
        {categoryMovements.length > 0 ? (
          <>
            {categoryMovements.map(
              (movement, index) =>
                movement.movement !== undefined &&
                movement.movement !== null && (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                      borderRadius: "4px",
                      backgroundColor: "#FFFFFF0F",
                      padding: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor:
                            movement.chartColor ||
                            (movement.movement >= 0 ? "#4CAF50" : "#F44336"),
                          marginRight: "12px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "white",
                          fontStyle: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {movement.cost_structure_type}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        color: movement.movement >= 0 ? "#4CAF50" : "#F44336",
                        fontStyle: "Roboto",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      {movement.movement.toFixed(2)}%
                      {movement.movement >= 0 ? (
                        <TrendingUp
                          sx={{ fontSize: "18px", marginLeft: "4px" }}
                        />
                      ) : (
                        <TrendingDown
                          sx={{ fontSize: "18px", marginLeft: "4px" }}
                        />
                      )}
                    </Typography>
                  </Box>
                )
            )}

            {/* {averageMovement !== null && (
            <Box style={{ alignItems: 'center', 
              // marginBottom: '8px' ,
             //  border:'1px solid',
              borderRadius:'4px',
              backgroundColor: '#FFFFFF0F',padding:'12px'}}>
             <Typography 
              //  variant="body2"
               color="white"
               sx={{ 
                //  marginTop: '12px',
                 fontStyle: 'Roboto', fontWeight:500, fontSize: '14px'
               }}
             >
               Overall category moved by{' '}
               <Typography 
                 component="span" 
                 sx={{ 
                   color: averageMovement >= 0 ? '#4CAF50' : '#F44336',
                   fontStyle: 'Roboto', fontWeight:500, fontSize: '14px'
                 }}
               >
                 {averageMovement.toFixed(2)}%
               </Typography>
               .
             </Typography>
             </Box>
           )} */}
          </>
        ) : (
          <Typography
            variant="body2"
            color="white"
            sx={{
              textAlign: "center",
              color: "#FFFFFF80",
            }}
          >
            No data on movements available
          </Typography>
        )}
      </MovementContainer>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Container>
          {/* Header Section - Fixed */}
          <div
            style={{
              position: "sticky", // Keeps this header fixed as you scroll the chart
              top: 0, // Ensures it stays at the top of its container
              zIndex: 10, // Keeps it above other scrollable content
              // backgroundColor: "#FFFFFF0F", // Set a background color to avoid transparency issues
              paddingBottom: "16px", // Space below header
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "18px",
                color: "white",
                marginBottom: "1.5rem",
              }}
            >
              <b>Commodity prices for top categories</b>
            </Typography>

            {filteredResults && filteredResults.length > 0 ? (
              <Select
                sx={{
                  maxWidth: "120px",
                  width: "auto",
                  height: "24px",
                  padding: "4px 8px",
                  fontStyle: "Roboto",
                  fontSize: "12px",
                  fontWeight: 500,
                  gap: "4px",
                  borderRadius: "4px",
                  opacity: 1,
                  background: "#FFFFFF0F",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #E2E8F00F",
                  marginBottom: "1.5rem",
                  marginLeft: "auto", // This will push the select to the right
                  "& .MuiSelect-select": {
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                IconComponent={(props) => (
                  <ArrowDropDownCircle
                    {...props}
                    style={{
                      color: "white",
                      fontSize: "12px",
                    }}
                  />
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      background: "white",
                    },
                  },
                  MenuListProps: {
                    sx: {
                      fontStyle: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "15px",
                    },
                  },
                }}
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                {filteredResults.map((category) => (
                  <MenuItem
                    key={category.category}
                    value={category.category}
                    sx={{
                      fontSize: "12px",
                      fontStyle: "Roboto",
                      fontWeight: 500,
                      lineHeight: "15px",
                    }}
                  >
                    {category.category}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <LoadingGif />
            )}
          </div>

          {/* Chart Section - Scrollable */}
          {selectedCategory && (
            <Box
              sx={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                height: "100%",
                position: "relative",
                "&::-webkit-scrollbar": {
                  height: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0,0,0,0.5)",
                  borderRadius: "100px",
                  visibility: "hidden",
                },
                "&:hover::-webkit-scrollbar-thumb": {
                  visibility: "visible",
                  backgroundColor: "rgba(0,0,0,0.7)",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgba(0,0,0,0.9)",
                },
                scrollbarWidth: "none", // For Firefox (auto-hide)
                "&:hover": {
                  "&::-webkit-scrollbar-thumb": {
                    visibility: "visible",
                  },
                },
              }}
            >
              <Box
                sx={{
                  minWidth: "150%",
                  display: "inline-block",
                }}
              >
                {renderChart()}
              </Box>
            </Box>
          )}
        </Container>
      </Grid>

      <Grid item xs={4}>
        {renderMovements()}
      </Grid>
    </Grid>
  );
};
