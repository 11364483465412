import React, { useState, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Paper,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  SvgIcon,
  Skeleton,
  Breadcrumbs,
} from "@mui/material";
import {
  ArrowForward,
  AutoAwesome,
  Close as CloseIcon,
  Download,
  PanToolAlt,
  PersonAddAlt,
  Portrait,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import bggenai from "../../assets/bggenai.png";
import bgsd from "../../assets/bgsd.png";
import ailoader from "../../assets/ailoader.gif";
import bgSS from "../../assets/bgSS.png";
import { DeleteIcon, Search } from "lucide-react";
import { Tooltip as MUITooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import supro from "../../assets/supro.png";

export const SupplierInsightContainer = ({
  title,
  top3CategoriesSup,
  supDandP,
  supCustInfo,
  riskScore,
  scoreCommentary,
  revenue,
  supplierNews,
  spendSummary,
  supplierProData,
  kpdbData,
}) => {
  const LoadingGif = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50px",
        width: "auto",
      }}
    >
      <img
        src={ailoader}
        alt="Loading..."
        style={{ width: "30px", height: "30px" }}
      />
    </Box>
  );

  const extractedKPDBData =
    Object.keys(kpdbData).length > 0 ? Object.entries(kpdbData)[0][1] : {};

  const hasKPDBData = Object.keys(extractedKPDBData).length > 0;

  const hasSupplierProData = supplierProData === "Yes";

  const tableHeaders = [
    "Supplier Name",
    "Spend",
    "Supplier Details",
    "Key Products and Services",
    {
      label: "Risk Score",
      sublabel: "(source: D&B)",
      type: "complex",
    },
    {
      label: "Revenue in USD",
      sublabel: "(source: D&B)",
      type: "complex",
    },
    "Spend as a % of Revenue",
  ];
  // Check if supplierProData is not "N/A" and add 'Action' column
  if (supplierProData === "Yes") {
    tableHeaders.push("Supplier Profile");
  }

  const kpdbDataKey = Object.keys(kpdbData)[0] || "";

  tableHeaders.push(
    "# of clients observed in KPDB",
    `% of ${kpdbDataKey} spend with clients (range: min - max %)`,
    "Spend observed in KPDB",
    "Website"
  );

  // console.log('Extracted KPDB Data:', extractedKPDBData);

  const [selectedCategory, setSelectedCategory] = useState("Overall");

  const [openDialog, setOpenDialog] = useState(false);
  const [openNewsDialog, setOpenNewsDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleNewsClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleNewsDialogOpen = () => {
    setOpenNewsDialog(true);
  };

  const handleNewsDialogClose = () => {
    setOpenNewsDialog(false);
  };

  const sortedNews =
    selectedCategory && supplierNews[selectedCategory]
      ? supplierNews[selectedCategory].sort(
          (a, b) => new Date(b.formatted_date) - new Date(a.formatted_date)
        )
      : [];

  const gridItemStyle = {
    border: "1px solid #4a5c6e",
    p: 1,
    backgroundColor: "rgba(44, 62, 80, 0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px", // Adjust this value as needed
  };
  const formatRevenue = (revenue) => {
    const number = parseFloat(revenue);

    if (number >= 1e9) {
      return `$${(number / 1e9).toFixed(2)} Bn`;
    } else if (number >= 1e6) {
      return `$${(number / 1e6).toFixed(2)} Mn`;
    } else {
      return `$${(number / 1e6).toFixed(2)} Mn`; // Convert to millions even if less than 1 million
    }
  };

  const formatSpend = (revenue) => {
    const number = parseFloat(revenue);

    // Determine the currency symbol based on currency_value
    const currencySymbol =
      spendSummary.spend_summary.currency_value === "EUR"
        ? "€"
        : spendSummary.spend_summary.currency_value === "USD"
        ? "$"
        : spendSummary.spend_summary.currency_value === "GBP"
        ? "£"
        : spendSummary.spend_summary.currency_value === "AUD"
        ? "A$"
        : spendSummary.spend_summary.currency_value; // If not one of the listed currencies, use the raw value

    // Format the number based on its size
    if (number >= 1e9) {
      return `${currencySymbol}${(number / 1e9).toFixed(0)} Bn`;
    } else if (number >= 1e6) {
      return `${currencySymbol}${(number / 1e6).toFixed(0)} Mn`;
    } else {
      // Convert to millions even if less than 1 million
      return `${currencySymbol}${(number / 1e6).toFixed(0)} Mn`;
    }
  };

  const getSupplierInfo = (supplier, category) => {
    if (category === "Overall") {
      // Search for the supplier in all categories
      for (const cat in supDandP) {
        if (supDandP[cat][supplier]) {
          return { details: supDandP[cat][supplier], category: cat };
        }
      }
      return { details: null, category: null };
    }
    return { details: supDandP[category][supplier], category };
  };

  const getCustomerInfo = (supplier, category) => {
    if (category === "Overall") {
      // Search for the supplier in all categories
      for (const cat in supCustInfo) {
        if (supCustInfo[cat][supplier]) {
          return supCustInfo[cat][supplier];
        }
      }
      return null;
    }
    return supCustInfo[category][supplier];
  };

  const DEFAULT_SUPPLIER_INFO = {
    A: {
      riskScore: 1,
      scoreCommentary:
        "Limited business activity signals reported in recent months",
      revenue: 9690000000, // $500 million
    },
    B: {
      riskScore: 1,
      scoreCommentary: "Age of financial statement indicates higher risk",
      revenue: 1300000000, // $1.2 billion
    },
    C: {
      riskScore: 1,
      scoreCommentary:
        "Limited business activity signals reported in recent months",
      revenue: 1200000000, // $1.2 billion
    },
    D: {
      riskScore: 1,
      scoreCommentary:
        "Limited business activity signals reported in recent months",
      revenue: 1360000000, // $1.2 billion
    },
    E: {
      riskScore: 2,
      scoreCommentary: "Negative change in net worth",
      revenue: 25275456168, // $1.2 billion
    },
    F: {
      riskScore: 2,
      scoreCommentary: "Negative change in net worth",
      revenue: 1650442469, // $1.2 billion
    },

    G: {
      riskScore: 1,
      scoreCommentary: "Age of financial statement indicates higher risk",
      revenue: 787917238, // $1.2 billion
    },
    H: {
      riskScore: 1,
      scoreCommentary: "Age of financial statement indicates higher risk",
      revenue: 2668710, // $1.2 billion
    },
    I: {
      riskScore: 1,
      scoreCommentary:
        "Ranks within the lower third of the businesses in the global supply base for risk of ceasing operations or filing for bankruptcy",
      revenue: 204329, // $1.2 billion
    },
  };

  const [supplierProDatavf, setSupplierProDatavf] = useState(null);
  const [loadingSp, setLoadingSp] = useState(true);
  const [errorSp, setErrorSp] = useState(null);

  const navigate = useNavigate();
  const handleOpenSupplierProfile = useCallback(async (supplierName) => {
    try {
      const url = `/supplierprofile/${encodeURIComponent(supplierName)}`;
      window.open(url, "_blank"); // Open in a new tab
    } catch (error) {
      console.error("Error opening supplier profile in a new tab:", error);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF08",
        width: "100%",
        padding: "16px",
        gap: "16px",
        border: "1px solid #FFFFFF0F",
        borderRadius: "10px",
        p: 2,
      }}
    >
      {/* {insights[selectedCategory] && (
                <Typography variant="body1" color="white" sx={{ mb: 2 }}>
                    {extractFirstNumber(insights[selectedCategory][0])} suppliers contribute to 80% spend
                </Typography>
            )} */}
      <Typography
        variant="h6"
        color="white"
        style={{
          fontStyle: "Roboto",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "18px",
          color: "white",
        }}
      >
        <b>Supplier Deep Dive</b>
      </Typography>
      <Box
        sx={{
          display: "flex",
          mb: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          placeholder="Search suppliers..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: "192px",
            backgroundColor: "#FFFFFF0F",
            border: "1px solid #E2E8F00F",
            boxShadow: "0px 1px 2px 0px #1018280D",
            height: "28px",
            borderRadius: "4px",
            // marginLeft: "8px", // Add some space between Select and TextField
            color: "white",
            "& .MuiOutlinedInput-root": {
              width: "192px",
              backgroundColor: "#FFFFFF0F",
              border: "1px solid #E2E8F00F",
              boxShadow: "0px 1px 2px 0px #1018280D",
              height: "28px",
              fontSize: "12px",
              color: "white",
              borderRadius: "4px",
              "& fieldset": {
                border: "1px solid #E2E8F00F",
              },
              "&:hover fieldset": {
                border: "1px solid #E2E8F00F",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid #E2E8F00F",
              },
            },
            "& .MuiInputLabel-root": {
              color: "rgba(255, 255, 255, 0.7)",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search
                  style={{ color: "white", width: "14px", height: "14px" }}
                />
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          onClick={handleNewsDialogOpen}
          sx={{
            width: "150px",
            height: "28px",
            padding: "4px",
            gap: "4px",
            borderRadius: "4px",
            // opacity: 0,
            // marginLeft: "8px", // Add some space between TextField and Button
            background: "#BE3FF5",
            "&:hover": {
              background: "#BE3FF5",
            },
            textTransform: "none",
          }}
        >
          <Typography
            sx={{
              fontStyle: "Roboto",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "15px",
              textAlign: "left",
            }}
          >
            Supplier spotlight{" "}
            <ArrowForward sx={{ ml: 0.5, height: "20px", width: "20px" }} />
          </Typography>
        </Button>
      </Box>

      {/* <Grid container sx={{ mb: 1 }} justifyContent="space-between"> */}
      <Box
        sx={{
          mb: 1,
          maxWidth: "100%",
          overflowX: hasKPDBData ? "auto" : "hidden",
          border: "1px solid #FFFFFF0F",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF0F",
          // Scrollbar styling
          "&::-webkit-scrollbar": {
            width: "2px",
            height: "10px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#2a2b2f",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
            "&:hover": {
              background: "#555",
            },
          },
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            tableLayout: "fixed",
            width: hasSupplierProData ? "150%" : hasKPDBData ? "160%" : "100%",
          }}
        >
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => {
                // Handle complex headers with sublabels
                const headerContent =
                  header.type === "complex" ? (
                    <span>
                      {header.label}{" "}
                      <span style={{ fontStyle: "italic" }}>
                        <br />
                        {header.sublabel}
                      </span>
                    </span>
                  ) : (
                    header
                  );

                return (
                  <TableCell
                    key={index}
                    sx={{
                      color: "white",
                      fontStyle: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "8px",
                      borderBottom: "1px solid #FFFFFF0F",
                      backgroundColor: "#353641B2",
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: 500,
                        lineHeight: "14px",
                        padding: "4px",
                        textAlign: "left",
                      }}
                    >
                      <b>{headerContent}</b>
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(top3CategoriesSup)
              .filter(([supplier]) =>
                searchTerm
                  ? supplier.toLowerCase().includes(searchTerm.toLowerCase())
                  : true
              )
              .map(([supplier, category], index) => {
                const supplierDetails = supDandP[supplier];
                const defaultInfo = DEFAULT_SUPPLIER_INFO[supplier];
                const currentRiskScore = defaultInfo
                  ? defaultInfo.riskScore
                  : riskScore[supplier];
                const currentScoreCommentary = defaultInfo
                  ? defaultInfo.scoreCommentary
                  : scoreCommentary[supplier];

                console.log("revneue is", revenue[supplier]);
                const supRevenue =
                  Array.isArray(revenue[supplier]) &&
                  revenue[supplier].length > 1
                    ? parseFloat(revenue[supplier][1].split(" ")[0]) // Get USD value
                    : revenue[supplier];

                console.log("supRevenue is", supRevenue);

                const formattedRevenue = supRevenue
                  ? formatRevenue(supRevenue)
                  : "N/A";
                // Exchange rates (mock values; replace with real API data if available)

                console.log("formattedRevenue is", formattedRevenue);
                const exchangeRates = {
                  EUR: 1.1,
                  AUD: 0.65,
                  GBP: 1.3,
                  USD: 1,
                };

                const convertToUSD = (amount, currency) => {
                  const rate = exchangeRates[currency];
                  return rate ? amount * rate : null;
                };

                const spendAmount = category;
                const revenueAmount = supRevenue || 0;

                let spendPercentage;

                if (defaultInfo && supRevenue) {
                  spendPercentage = (spendAmount / supRevenue) * 100;
                } else {
                  const revenueCurrency =
                    spendSummary.spend_summary.currency_value;
                  const spendCurrency =
                    spendSummary.spend_summary.currency_value;

                  const usdRevenue =
                    revenueAmount && typeof revenueAmount === "string"
                      ? revenueAmount.split(" ")[0]
                      : revenueAmount;

                  const numericUsdRevenue = parseFloat(usdRevenue) || 0;

                  const spendInUSD = convertToUSD(spendAmount, spendCurrency);
                  const revenueInUSD = convertToUSD(
                    numericUsdRevenue,
                    revenueCurrency
                  );

                  spendPercentage =
                    spendInUSD && revenueInUSD && revenueInUSD > 0
                      ? (spendInUSD / revenueInUSD) * 100
                      : null;
                }
                console.log("spend percentage is", spendPercentage);
                // Check if revenue is less than spendAmount, otherwise set to N/A
                const showRevenueAndPercentage = true
                  ? revenueAmount > spendAmount
                  : false;
                const displayedRevenue = formattedRevenue
                  ? formattedRevenue
                  : "N/A";
                const displayedSpendPercentage =
                  showRevenueAndPercentage && spendPercentage !== null
                    ? `${spendPercentage.toFixed(2)}%`
                    : "N/A";

                const supplierKPDBData = extractedKPDBData[supplier] || {
                  numClients: "N/A",
                  packagingSpendRange: "N/A",
                  spendInKPDB: "N/A",
                  website: "N/A",
                };

                return (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: "1px solid #FFFFFF0F",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      textAlign: "left",
                    }}
                  >
                    {[
                      supplier || <Skeleton width={120} height={20} />,
                      formatSpend(category) || (
                        <Skeleton width={120} height={20} />
                      ),
                      supplierDetails?.details || (
                        <Skeleton width={150} height={20} />
                      ),
                      supplierDetails?.products || (
                        <Skeleton width={180} height={20} />
                      ),
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        {currentRiskScore !== undefined ? (
                          <>
                            {currentRiskScore !== 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  width: "75%",
                                  height: "15px",
                                  backgroundColor: "#e0e0e0",
                                  borderRadius: "7.5px",
                                  overflow: "hidden",
                                  marginBottom: "8px",
                                }}
                              >
                                {[0, 1, 2, 3, 4].map((_, i) => {
                                  let color;
                                  // Set colors based on currentRiskScore value
                                  if (currentRiskScore <= 2) {
                                    color = "#15D638"; // Green for 0, 1, 2
                                  } else if (currentRiskScore === 3) {
                                    color = "#EA580C"; // Orange for 3
                                  } else {
                                    color = "#EF4444"; // Red for 4 and 5
                                  }
                                  return (
                                    <div
                                      key={i}
                                      style={{
                                        width: "20%",
                                        height: "100%",
                                        backgroundColor:
                                          i < currentRiskScore
                                            ? color
                                            : "transparent",
                                        borderRight:
                                          i < 4 ? "1px solid #fff" : "none",
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            )}
                            <Typography
                              variant="body2"
                              component="i"
                              sx={{
                                lineHeight: 1.6,
                                textAlign: "left",
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "white",
                              }}
                            >
                              {Array.isArray(currentScoreCommentary) &&
                              currentScoreCommentary.length > 0
                                ? currentScoreCommentary[0]
                                : currentScoreCommentary || "N/A"}
                            </Typography>
                          </>
                        ) : null}
                      </div>,
                      displayedRevenue,
                      displayedSpendPercentage,
                      <MUITooltip title="View supplier profile" arrow>
                        <Button
                          // variant="contained"
                          onClick={() => handleOpenSupplierProfile(supplier)}
                          sx={{
                            // width: "15px",
                            // height: "28px",
                            padding: "4px 8px",
                            // border: '1px solid #BE3FF5',
                            // borderRadius: "4px",
                            // background: "#BE3FF5",
                            // "&:hover": {
                            //   background: "#BE3FF5",
                            // },
                            textTransform: "none",
                            color: "#FFFFFF",
                            fontSize: "12px",
                          }}
                        >
                          {/* <PersonAddAlt sx={{height:'20px', width:'20px'}}/> */}
                          <img
                            src={supro}
                            style={{ height: "25px", width: "25px" }}
                          />
                          {/* Generate Profile */}
                        </Button>
                      </MUITooltip>,
                      supplierKPDBData.numClients || "N/A",
                      supplierKPDBData.packagingSpendRange || "N/A",
                      supplierKPDBData.spendInKPDB || "N/A",
                      supplierKPDBData.website || "N/A",
                    ].map((cell, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          color: "white",
                          padding: "8px",
                          textAlign: "left",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            lineHeight: 1.6,
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "white",
                            textAlign: "left",
                            padding: "4px",
                          }}
                        >
                          {cell}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>

      <Dialog
        open={openNewsDialog}
        onClose={handleNewsDialogClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundImage: `url(${bggenai})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "rgba(129, 129, 129)",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle sx={{ color: "white", fontSize: "0.9rem" }}>
          Latest Market Insights
          <IconButton
            onClick={handleNewsDialogClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {supplierNews ? (
          <DialogContent
            dividers
            sx={{ borderColor: "rgba(255, 255, 255,0.5)" }}
          >
            {supplierNews.map((newsItem, index) => (
              <Card
                key={index}
                sx={{
                  mb: 2,
                  display: "flex",
                  backgroundImage: `url(${bggenai})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "rgba(129, 129, 129)",
                  boxShadow: "none",
                  border: "1px solid rgba(255, 255, 255)",
                  borderRadius: "8px",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: "199px",
                    height: "152px",
                    borderRadius: "8px 0 0 0",
                  }}
                  image={
                    newsItem.thumbnail ||
                    "https://www.ship-technology.com/wp-content/uploads/sites/8/2022/02/GettyImages-968819844-scaled.jpg"
                  }
                  alt="News thumbnail"
                />
                <CardContent
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    color: "white",
                    fontSize: "0.9rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontSize: "0.9rem" }}
                  >
                    {newsItem.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ fontSize: "0.9rem" }}
                  >
                    <a
                      href={newsItem.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {newsItem.link}
                    </a>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="rgba(255, 255, 255, 0.7)"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {newsItem.source_name}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="rgba(255, 255, 255, 0.7)"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {newsItem.formatted_date}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </DialogContent>
        ) : (
          <DialogContent
            dividers
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              borderColor: "rgba(255, 255, 255,0.5)",
              color: "white",
              fontSize: "1rem",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              minHeight="200px"
            >
              <LoadingGif />
              <Typography
                variant="body1"
                sx={{
                  fontStyle: "Roboto",
                  fontSize: "14px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <i>Loading...</i>
              </Typography>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </Box>
  );
};

const SpendChartContainer = ({ title, spendChartData }) => {
  const formatLargeNumber = (number) => {
    if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + "B";
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "M";
    } else {
      return number.toString();
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{ p: 2, mb: 2, backgroundColor: "rgb(27, 38, 53)", width: "100%" }}
    >
      <Typography variant="h6" sx={{ mb: 2, color: "white" }}>
        {title}
      </Typography>
      <Box sx={{ width: "100%", height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={spendChartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" tick={{ fill: "white" }} />
            <YAxis tickFormatter={formatLargeNumber} tick={{ fill: "white" }} />
            <Tooltip
              formatter={(value) => [formatLargeNumber(value), "Spend"]}
              contentStyle={{
                backgroundColor: "rgb(35, 48, 68)",
                border: "none",
              }}
              labelStyle={{ color: "white" }}
              itemStyle={{ color: "white" }}
              cursor={{ fill: "transparent" }}
            />
            <Bar dataKey="spend" fill="rgb(71, 130, 218)" barSize={80} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};
