import {
  AppBar,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CssBaseline,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Chip,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useRef, useState, useEffect, useMemo } from "react";
import bgsd from "../../assets/bgsd.png";
import Logo from "../../assets/Logo.svg";
import Awards from "../../assets/awards.png";
import {
  AttachMoney,
  AttachMoneyOutlined,
  BarChart,
  BarChartOutlined,
  EmojiEvents,
  EmojiEventsOutlined,
  GridOn,
  GridViewOutlined,
  Info,
  LocalShipping,
  LocalShippingOutlined,
  LocalShippingRounded,
  LockOutlined,
  NavigateNext,
} from "@mui/icons-material";
import { SidebarFixed } from "./ProSidebar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../constants";
// import { Award } from "lucide-react";

const supplierData = {
  "Awards and Recognition": [
    {
      description:
        "Award by Foundr Magazine India. This recognition highlights the company's leadership and innovative approaches in the industry",
      title: "DELHI'S MOST INSPIRING BUSINESS LEADERS RENOVATE THE FUTURE",
    },
    {
      description:
        "AeroPlast Limited has received this award, which acknowledges its performance and contributions as a Micro, Small, and Medium Enterprise (MSME)",
      title: "India 5000 Best MSME Awards",
    },
    {
      description:
        "The company holds this certification, indicating its adherence to international standards for quality management",
      title: "ISO 9001 Certification",
    },
    {
      description:
        "Authorization under Hazardous and Other Wastes (Management & Transboundary Movement) Rules: This authorization reflects the company's compliance with environmental regulations",
      title: "HSPCB Authorization",
    },
    {
      description:
        "Phenomenal revenue growth of 81% by the end of 2019, driven by the support of vendors and renowned clients such as UNIQLO, AMAZON, and others",
      title: "Revenue Growth Achievement",
    },
  ],
  ESG: {
    Ethnicity: ["English"],
    Ownership: ["-"],
    "Small Business Classification": ["-"],
    "Scope 1,2,3 emissions": ["-"],
  },
  "Financial information": {
    EBITDA: {},
    "Gross margin and net margin": {},
    "Revenue USD": ["$0.0B"],
    "Revenue by year (trend)": [
      {
        2022: "$17.5B",
        2023: "$22.7B",
      },
    ],
  },
  Logo: {
    "Logo URL": [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdtPpKiF3oDoEu9JCPc5JD4KfgJZPjMCOoqA&s",
      // "https://serpapi.com/searches/673e01b27c17128202840d2d/images/0df83d3ea02393b42cc5cd3cafcf8a61bc833a4a4481ce1989507e45c5e362f2.jpeg"
    ],
  },
  "SWOT Analysis": {
    Opportunities: [
      {
        description:
          "The increasing demand for biodegradable and recyclable packaging solutions presents a significant opportunity for Aero Plast Limited.",
        name: "Growing Demand for Sustainable Packaging",
      },
      {
        description:
          "Expanding into new geographic markets and industries could help the company grow its customer base and revenue.",
        name: "Diversification into New Markets",
      },
      {
        description:
          "Adopting new technologies and manufacturing processes can enhance efficiency and product quality, further solidifying the company's market position.",
        name: "Technological Advancements",
      },
    ],
    Strengths: [
      {
        description:
          "Aero Plast Limited has a strong presence in the packaging industry with over 29 years of operation.",
        name: "Established Market Presence",
      },
      {
        description:
          "The company has a world-class manufacturing facility with a significant production capacity and large area.",
        name: "Advanced Manufacturing Facilities",
      },
      {
        description:
          "Aero Plast offers a wide range of packaging solutions catering to various industries.",
        name: "Diverse Product Range",
      },
      {
        description:
          "The company provides customized products with high precision and quality, including FSC C.O.C certified bags.",
        name: "Customization and Quality",
      },
      {
        description:
          "Aero Plast offers strategic sourcing services that help in reducing costs, improving quality, and enhancing lead times.",
        name: "Strategic Sourcing",
      },
    ],
    Threats: [
      {
        description:
          "The packaging industry is highly competitive, which could pose a threat to Aero Plast Limited's market share.",
        name: "Competition in the Packaging Industry",
      },
      {
        description:
          "Changes in environmental and packaging regulations could impact the company's operations and product offerings.",
        name: "Regulatory Changes",
      },
      {
        description:
          "Fluctuations in the prices of raw materials could affect the company's cost structure and profitability.",
        name: "Raw Material Price Volatility",
      },
    ],
    Weaknesses: [
      {
        description:
          "Being an unlisted public company, Aero Plast Limited may face limitations in accessing capital markets and public funding.",
        name: "Limited Access to Capital Markets",
      },
      {
        description:
          "The lack of recent financial transparency due to outdated financial updates and AGM.",
        name: "Outdated Financial Updates",
      },
      {
        description:
          "The company's primary operations are centered in Northern India, which may limit its expansion and market reach.",
        name: "Limited Geographic Expansion",
      },
    ],
  },
  "supplier overview": {
    "Business units": ["BU1", "BU2", "BU3"],
    Capacity: [
      "Bahadurgarh Plant: 800 MT/Month Utilization 70%",
      "GandhiDham Plant:2500 MT/Month Utilization 65%",
      "Kathua Plant:2500 MT/Month Starting in March 2025",
    ],
    "Categories operated": [
      "Manufacturing",
      "Rubber and Plastic Product Manufacturing",
      "Manufacture of plastic products",
      "Manufacture of plastics products",
      "Plastics products",
    ],
    "Company overview": [
      "Aero Plast Limited is involved in the production of laminate rolls and pan inlays, serving key customers such as Big Basket, Flipkart, and Zomato across India.",
    ],
    "Customer base": ["-"],
    "Duns Id": ["917000000"],
    Headquarters: ["Delhi , India"],
    "Key products and services": ["laminate rolls and pan inlays."],
    "No of employees": ["327"],
    "No of plants": ["3.0"],
    "Parent Company": ["Msft"],
    "Plant locations": [
      "Bahadurgarh, Haryana",
      "Gandhidham, Gujarat",
      "Kathua, Jammu'",
    ],
    "Regions Served": ["Asia Pacific"],
    "Supplier Contact": {
      Address: [
        "A10-Second Floor Mangolpuri Industrial Area Phase II Delhi-110034",
      ],
      Email: [
        "Ag@aeroplastltd.com",
        "Abhas@aeroplastltd.com",
        "Apl@aeroplastltd.com",
      ],
      Telephone: ["1142475350"],
      Website: ["-"],
    },
    "Year of establishment": ["1978"],
  },
};
// Create a custom Typography component
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontStyle: "Roboto",
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "21.59px",
  letterSpacing: "-0.4px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#FFFFFF99",
  flex: 1,

  // Alternate text alignments
  "&:nth-child(2)": {
    textAlign: "center",
  },
  "&:nth-child(3)": {
    textAlign: "right",
  },

  // Color change for later rows
  "&:nth-of-type(n+7)": {
    color: "#FFFFFF",
  },
}));

const SupplierProfile = () => {
  const { supplierName } = useParams(); // Get supplierName from URL
  const [supplierProDatavf, setSupplierProDatavf] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // Company Overview State Variables
  const [companyOverview, setCompanyOverview] = useState(["N/A"]);
  const [dunsId, setDunsId] = useState(["N/A"]);
  const [parentCompany, setParentCompany] = useState(["N/A"]);
  const [headquarters, setHeadquarters] = useState(["N/A"]);
  const [categoriesOperated, setCategoriesOperated] = useState(["N/A"]);
  const [yearofest, setYearofest] = useState(["N/A"]);
  const [businessUnits, setBusinessUnits] = useState(["N/A"]);
  const [customerBase, setCustomerBase] = useState(["N/A"]);
  const [noOfPlants, setNoOfPlants] = useState(["N/A"]);
  const [plantLocations, setPlantLocations] = useState(["N/A"]);
  const [keyProductsAndServices, setKeyProductsAndServices] = useState(["N/A"]);
  const [noOfEmployees, setNoOfEmployees] = useState(["N/A"]);
  const [regionsServed, setRegionsServed] = useState(["N/A"]);
  const [capacity, setCapacity] = useState(["N/A"]);

  // Contact Information State Variables
  const [address, setAddress] = useState(["N/A"]);
  const [email, setEmail] = useState(["N/A"]);
  const [telephone, setTelephone] = useState(["N/A"]);
  const [website, setWebsite] = useState(["N/A"]);
  const [logoURL, setLogoURL] = useState(["N/A"]);

  // Financial Information State Variables
  const [revenueUSD, setRevenueUSD] = useState(["N/A"]);
  const [revenueTrend, setRevenueTrend] = useState(["N/A"]);
  const [margin, setMargin] = useState(["N/A"]);
  const [ebitda, setEbitda] = useState(["N/A"]);

  // SWOT Analysis State Variables
  const [opportunities, setOpportunities] = useState(["N/A"]);
  const [strengths, setStrengths] = useState(["N/A"]);
  const [threats, setThreats] = useState(["N/A"]);
  const [weaknesses, setWeaknesses] = useState(["N/A"]);

  // Awards and Recognition State Variable
  const [awardsAndRecognition, setAwardsAndRecognition] = useState(["N/A"]);

  // ESG State Variables
  const [ethinicity, setEthnicity] = useState(["N/A"]);
  const [ownership, setOwnership] = useState(["N/A"]);
  const [smallBusinessClassification, setSmallBusinessClassification] =
    useState(["N/A"]);
  const [scope1_2_3_Targets, setScope1_2_3_Targets] = useState(["N/A"]);

  useEffect(() => {
    const fetchSupplierProfile = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${BACKEND_BASE_URL}/get_supplier_profile?supplierName=${supplierName.replace(
            / /g,
            "+"
          )}`
        );

        const parsedData = response.data;
        console.log("Parsed Data:", parsedData);

        // Set states directly
        setSupplierProDatavf(parsedData);

        setCompanyOverview(parsedData["supplier overview"]["Company overview"]);
        setDunsId(parsedData["supplier overview"]["Duns Id"]);
        setParentCompany(parsedData["supplier overview"]["Parent Company"]);
        setHeadquarters(parsedData["supplier overview"]["Headquarters"]);
        setCategoriesOperated(
          parsedData["supplier overview"]["Categories operated"]
        );
        setYearofest(parsedData["supplier overview"]["Year of establishment"]);
        setBusinessUnits(parsedData["supplier overview"]["Business units"]);
        setCustomerBase(parsedData["supplier overview"]["Customer base"]);
        setNoOfPlants(parsedData["supplier overview"]["No of plants"]);
        setPlantLocations(parsedData["supplier overview"]["Plant locations"]);
        setKeyProductsAndServices(
          parsedData["supplier overview"]["Key products and services"]
        );
        setNoOfEmployees(parsedData["supplier overview"]["No of employees"]);
        setRegionsServed(parsedData["supplier overview"]["Regions Served"]);
        setCapacity(parsedData["supplier overview"]["Capacity"]);

        // Contact Information
        setAddress(
          parsedData["supplier overview"]["Supplier Contact"]["Address"]
        );
        setEmail(parsedData["supplier overview"]["Supplier Contact"]["Email"]);
        setTelephone(
          parsedData["supplier overview"]["Supplier Contact"]["Telephone"]
        );
        setWebsite(
          parsedData["supplier overview"]["Supplier Contact"]["Website"]
        );

        // Logo
        setLogoURL(parsedData["Logo"]["Logo URL"]);

        // Financial Information
        setRevenueUSD(parsedData["Financial information"]["Revenue USD"]);
        setRevenueTrend(
          parsedData["Financial information"]["Revenue by year (trend)"]
        );
        setMargin(
          parsedData["Financial information"]["Gross margin and net margin"]
        );
        setEbitda(parsedData["Financial information"]["EBITDA"]);

        // SWOT Analysis
        setOpportunities(parsedData["SWOT Analysis"]["Opportunities"]);
        setStrengths(parsedData["SWOT Analysis"]["Strengths"]);
        setThreats(parsedData["SWOT Analysis"]["Threats"]);
        setWeaknesses(parsedData["SWOT Analysis"]["Weaknesses"]);

        // Awards and Recognition
        setAwardsAndRecognition(
          parsedData["Awards and Recognition"][0]["description"]
        );

        // ESG
        setEthnicity(parsedData["ESG"]["Ethnicity"]);
        setOwnership(parsedData["ESG"]["Ownership"]);
        setSmallBusinessClassification(
          parsedData["ESG"]["Small Business Classification"]
        );
        setScope1_2_3_Targets(parsedData["ESG"]["Scope 1,2,3 emissions"]);
      } catch (err) {
        setError("Failed to load supplier profile");
        console.error("Error fetching supplier profile:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSupplierProfile();
  }, [supplierName]);

  // useEffect(() => {
  //   const fetchSupplierProfile = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await axios.get(`http://localhost:5000/get_supplier_profile?supplierName=${supplierName.replace(/ /g, '+')}`);

  //       // Extensive logging
  //       console.log('FULL RESPONSE:', response);
  //       console.log('RESPONSE STATUS:', response.status);
  //       console.log('RESPONSE HEADERS:', response.headers);
  //       console.log('RESPONSE DATA TYPE:', typeof response.data);
  //       console.log('RESPONSE DATA KEYS:', Object.keys(response.data));
  //       console.log('ENTIRE RESPONSE DATA:', JSON.stringify(response.data, null, 2));

  //       // More defensive approach
  //       if (!response || !response.data) {
  //         console.error('No data received');
  //         setError('No data received from server');
  //         return;
  //       }

  //       // Try different access methods
  //       const data = response.data;
  //       console.log('Data access attempts:', {
  //         supplierOverview: data['supplier overview'],
  //         supplierOverviewCamel: data['supplierOverview'],
  //         supplierOverviewSnake: data['supplier_overview']
  //       });

  //     } catch (err) {
  //       console.error('FULL ERROR:', err);
  //       console.error('ERROR RESPONSE:', err.response);
  //       setError('Failed to load supplier profile');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchSupplierProfile();
  // }, [supplierName]);

  console.log("supplier name passed is", supplierName);
  // console.log('final pro data', supplierProDatavf);

  // const companyOverview = supplierProDatavf?.['supplier overview']?.['Company overview'] ?? ['N/A'];
  // const dunsId = supplierProDatavf?.['supplier overview']?.['Duns Id'] ?? ['N/A'];
  // const parentCompany = supplierProDatavf?.['supplier overview']?.['Parent Company'] ?? ['N/A'];
  // const headquarters = supplierProDatavf?.['supplier overview']?.Headquarters ?? ['N/A'];
  // const categoriesOperated = supplierProDatavf?.['supplier overview']?.['Categories operated'] ?? ['N/A'];
  // const yearofest = supplierProDatavf?.['supplier overview']?.['Year of establishment'] ?? ['N/A'];
  // const businessUnits = supplierProDatavf?.['supplier overview']?.['Business units'] ?? ['N/A'];
  // const customerBase = supplierProDatavf?.['supplier overview']?.['Customer base'] ?? ['N/A'];
  // const noOfPlants = supplierProDatavf?.['supplier overview']?.['No of plants'] ?? ['N/A'];
  // const plantLocations = supplierProDatavf?.['supplier overview']?.['Plant locations'] ?? ['N/A'];
  // const keyProductsAndServices = supplierProDatavf?.['supplier overview']?.['Key products and services'] ?? ['N/A'];
  // const noOfEmployees = supplierProDatavf?.['supplier overview']?.['No of employees'] ?? ['N/A'];
  // const regionsServed = supplierProDatavf?.['supplier overview']?.['Regions Served'] ?? ['N/A'];
  // const capacity = supplierProDatavf?.['supplier overview']?.Capacity ?? ['N/A'];
  // const address = supplierProDatavf?.['supplier overview']?.['Supplier Contact']?.Address ?? ['N/A'];
  // const email = supplierProDatavf?.['supplier overview']?.['Supplier Contact']?.Email ?? ['N/A'];
  // const telephone = supplierProDatavf?.['supplier overview']?.['Supplier Contact']?.Telephone ?? ['N/A'];
  // const website = supplierProDatavf?.['supplier overview']?.['Supplier Contact']?.Website ?? ['N/A'];
  // const logoURL = supplierProDatavf?.Logo?.['Logo URL'] ?? ['N/A'];
  // const revenueUSD = supplierProDatavf?.['Financial information']?.['Revenue USD'] ?? ['N/A'];
  // const revenueTrend = supplierProDatavf?.['Financial information']?.['Revenue by year (trend)'] ?? ['N/A'];
  // const opportunities = supplierProDatavf?.['SWOT Analysis']?.Opportunities ?? ['N/A'];
  // const strengths = supplierProDatavf?.['SWOT Analysis']?.Strengths ?? ['N/A'];
  // const threats = supplierProDatavf?.['SWOT Analysis']?.Threats ?? ['N/A'];
  // const weaknesses = supplierProDatavf?.['SWOT Analysis']?.Weaknesses ?? ['N/A'];
  // const awardsAndRecognition = supplierProDatavf?.['Awards and Recognition'] ?? ['N/A'];
  // const ethinicity = supplierProDatavf?.ESG?.Ethnicity ?? ['N/A'];
  // const ownership = supplierProDatavf?.ESG?.Ownership ?? ['N/A'];
  // const smallBusinessClassification = supplierProDatavf?.ESG?.['Small Business Classification'] ?? ['N/A'];
  // const scope1_2_3_Targets = supplierProDatavf?.ESG?.['Scope 1,2,3 Tagets'] ?? ['N/A'];

  const years = revenueTrend?.length > 0 ? Object.keys(revenueTrend[0]) : [];

  const columns = ["Metrics", ...years];

  const swotContainerStyle = {
    width: "550px",
    height: "550px",
    gap: "12px",
    border: "1px solid #FFFFFF0F",
    borderRadius: "8px",
    background: "#FFFFFF0F",
    boxSizing: "border-box", // Ensures padding is included in total height calculation
  };

  const boxStyle = {
    width: "1000px",
    // height: "740px",
    padding: "0px 0px 20px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  };

  const rowStyle = {
    display: "flex",
    justifyContent: "space-between",
    gap: "8px",
  };

  const borderBottomStyleSwot = {
    width: "550px",
    height: "36px",
    padding: "10px 12px",
    gap: "0px",
    borderBottom: "1px solid #FFFFFF0F",
  };

  const headingStyleSwot = {
    fontStyle: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15.6px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#FFFFFFCC",
  };

  const swotbulletpoints = {
    // width: '5px',
    height: "308px",
    padding: "12px 12px 12px 12px",
    gap: "20px",
  };

  const swotbulletStyle = {
    listStyleType: "disc",
    paddingLeft: "20px",
    margin: 0,
  };

  const swotlistStyle = {
    fontStyle: "Roboto",
    fontSize: "12px",
    fontWeight: 350,
    lineHeight: "20px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#FFFFFF",
  };

  const swotdescStyle = {
    fontStyle: "Roboto",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#FFFFFFCC",
  };

  const cardStyle = {
    width: "900x",
    height: "110px",
    padding: "16px 0px 0px 0px",
    gap: "16px",
    borderRadius: "8px",
    border: "1px solid #FFFFFF0F",
    display: "flex",
    alignItems: "center",
    marginBottom: "12px", // Add space between cards
  };

  const iconContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80px",
    height: "90px",
    marginLeft: "16px",
  };

  const contentStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const boxStyleESG = {
    // width: '900px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "90px", // Adjust the gap to control spacing between headings
    marginTop: "1rem",
  };

  const headingStyleESG = {
    fontStyle: "Roboto",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "21.59px",
    letterSpacing: "-0.4px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#FFFFFF99",
  };

  const valueStyleESG = {
    fontStyle: "Roboto",
    fontSize: "14px",
    fontWeight: 350,
    lineHeight: "21px",
    letterSpacing: "-0.4px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#FFFFFF",
  };

  const headingContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  };

  const supplierRef = useRef(null);
  const financialRef = useRef(null);
  const swotRef = useRef(null);
  const esgRef = useRef(null);
  const awardsRef = useRef(null);

  // Scroll to section method
  const scrollToSection = (elementRef) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      {/* <Box
          sx={{
            display: "flex",
            backgroundImage: `url(${bgsd})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: "100vh",
            width: "100%",
          }}
        > */}
      <Box
        component="main"
        sx={{
          display: "flex",
          flexGrow: 1,
          p: 3,
          backgroundImage: `url(${bgsd})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          width: "100%",
          // position:'absolute',
          // left:'2px'
        }}
      >
        {/* <div>
            <SidebarFixed />
          </div> */}
        <div>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: "100%",
              height: "65px",
              opacity: 1,
              borderBottom: "1px solid #2C2D3C",
              // padding: "8px 16px",
              paddingLeft: "35px",
              bgcolor: "#181921", // Keep the background consistent
            }}
          >
            <Toolbar
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between", // Align Typography to the left and Breadcrumbs to the right
                paddingRight: "16px",
              }}
            >
              {/* Typography on the left */}
              <Typography
                variant="h4"
                noWrap
                component="div"
                style={{
                  fontStyle: "Roboto",
                  fontSize: "18px",
                  fontWeight: 600,
                  // lineHeight: "14.52px",
                  textAlign: "left",
                  marginLeft: "2rem",
                }}
              >
                <img src={Logo} style={{ width: "159px", height: "40px" }} />
              </Typography>

              {/* Breadcrumbs on the right */}
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNext fontSize="small" />}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 0.6)",
                  fontStyle: "Roboto",
                  fontSize: "14px",
                  textDecoration: "none",
                  "& .MuiBreadcrumbs-separator": {
                    margin: "0 8px",
                  },
                }}
              ></Breadcrumbs>
            </Toolbar>
          </AppBar>
          {/* <SidebarFixed/> */}
          <Toolbar />

          <Box
            sx={{
              display: "flex",
              //   position: "relative",
              flexGrow: 1,
              p: 3,
              flexDirection: "column",
              minHeight: "100vh",
              // backgroundImage: `url(${bgsd})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%", // This will take remaining space
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                position: "relative",
                top: 0,
                left: 0,
                //   margin: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                }}
              >
                <Card
                  sx={{
                    width: "312px",
                    height: "320px",
                    // padding: "2px",
                    borderRadius: "8px",
                    background: "#FFFFFF0F",
                    border: "1px solid #FFFFFF1A",
                    backdropFilter: "blur(60px)",
                    color: "rgba(255, 255, 255, 0.9)",
                    ml: -4, // Shift left by 2 units (theme spacing, typically 8px x 2 = 16px)
                  }}
                >
                  <CardContent>
                    <List>
                      <ListItem
                        sx={{
                          padding: "8px",
                          paddingBottom: "16px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.1)",
                            border: "1px solid #FFFFFF1A",
                            borderRadius: "8px",
                            "& .MuiListItemIcon-root": {
                              color: "white !important",
                            },
                            "& .MuiListItemText-primary": {
                              color: "white !important",
                            },
                          },
                        }}
                        onClick={() => scrollToSection(supplierRef)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "35px",
                            color: "rgba(255, 255, 255, 0.6)",
                            // transition: 'color 0.3s ease', // Smooth color transition
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        >
                          {" "}
                          {/* Adjust this value */}
                          <LocalShippingOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary="Supplier overview"
                          primaryTypographyProps={{
                            color: "#FFFFFF99",
                            fontWeight: 350,
                            fontSize: "16px",
                            fontStyle: "Roboto",
                          }}
                        />
                      </ListItem>

                      <ListItem
                        sx={{
                          padding: "8px",
                          paddingBottom: "16px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.1)",
                            border: "1px solid #FFFFFF1A",
                            borderRadius: "8px",
                            "& .MuiListItemIcon-root": {
                              color: "white !important",
                            },
                            "& .MuiListItemText-primary": {
                              color: "white !important",
                            },
                          },
                        }}
                        onClick={() => scrollToSection(financialRef)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "35px",
                            color: "rgba(255, 255, 255, 0.6)",
                            // transition: 'color 0.3s ease', // Smooth color transition
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        >
                          {" "}
                          {/* Adjust this value */}
                          <AttachMoneyOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary="Financial information"
                          primaryTypographyProps={{
                            color: "#FFFFFF99",
                            fontWeight: 350,
                            fontSize: "16px",
                            fontStyle: "Roboto",
                          }}
                        />
                      </ListItem>

                      <ListItem
                        sx={{
                          padding: "8px",
                          paddingBottom: "16px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.1)",
                            border: "1px solid #FFFFFF1A",
                            borderRadius: "8px",
                            "& .MuiListItemIcon-root": {
                              color: "white !important",
                            },
                            "& .MuiListItemText-primary": {
                              color: "white !important",
                            },
                          },
                        }}
                        onClick={() => scrollToSection(swotRef)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "35px",
                            color: "rgba(255, 255, 255, 0.6)",
                            // transition: 'color 0.3s ease', // Smooth color transition
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        >
                          {" "}
                          {/* Adjust this value */}
                          <BarChartOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary="SWOT Analysis"
                          primaryTypographyProps={{
                            color: "#FFFFFF99",
                            fontWeight: 350,
                            fontSize: "16px",
                            fontStyle: "Roboto",
                          }}
                        />
                      </ListItem>

                      <ListItem
                        sx={{
                          padding: "8px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.1)",
                            border: "1px solid #FFFFFF1A",
                            borderRadius: "8px",
                            "& .MuiListItemIcon-root": {
                              color: "white !important",
                            },
                            "& .MuiListItemText-primary": {
                              color: "white !important",
                            },
                          },
                        }}
                        onClick={() => scrollToSection(awardsRef)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "35px",
                            color: "rgba(255, 255, 255, 0.6)",
                            // transition: 'color 0.3s ease', // Smooth color transition
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        >
                          {" "}
                          {/* Adjust this value */}
                          <EmojiEventsOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary="Awards & Recognition"
                          primaryTypographyProps={{
                            color: "#FFFFFF99",
                            fontWeight: 350,
                            fontSize: "16px",
                            fontStyle: "Roboto",
                          }}
                        />
                      </ListItem>

                      <ListItem
                        sx={{
                          padding: "8px",
                          paddingBottom: "16px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.1)",
                            border: "1px solid #FFFFFF1A",
                            borderRadius: "8px",
                            "& .MuiListItemIcon-root": {
                              color: "white !important",
                            },
                            "& .MuiListItemText-primary": {
                              color: "white !important",
                            },
                          },
                        }}
                        onClick={() => scrollToSection(esgRef)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "35px",
                            color: "rgba(255, 255, 255, 0.6)",
                            // transition: 'color 0.3s ease', // Smooth color transition
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        >
                          {" "}
                          {/* Adjust this value */}
                          <GridViewOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary="ESG"
                          primaryTypographyProps={{
                            color: "#FFFFFF99",
                            fontWeight: 350,
                            fontSize: "16px",
                            fontStyle: "Roboto",
                          }}
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>

                <Card
                  sx={{
                    width: "900px",
                    // height: "380px",
                    padding: "12px",
                    borderRadius: "8px",
                    background: "#FFFFFF0F",
                    border: "1px solid #FFFFFF1A",
                    gap: "4px",
                    marginRight: "-1.5rem", // This centers the card
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <img
                        src={logoURL}
                        alt="Logo"
                        style={{
                          width: "75px",
                          height: "75px",
                          gap: "0px",
                          borderRadius: "8px",
                          opacity: "1",
                          marginRight: "16px",
                        }}
                      />
                      <Box>
                        <Typography
                          style={{
                            fontStyle: "Roboto",
                            fontSize: "18px",
                            color: "#FFFFFF",
                            fontWeight: 350,
                            lineHeight: "37.03px",
                            letterSpacing: "-0.4000000059604645px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                          }}
                        >
                          {supplierName}
                        </Typography>
                        <Typography
                          style={{
                            fontStyle: "Roboto",
                            color: "#FFFFFF", // White color
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "24.72px",
                            letterSpacing: "-0.4px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: (Array.isArray(companyOverview)
                              ? companyOverview.join(" ")
                              : ""
                            ).replace(
                              /\*\*(.*?)\*\*/g,
                              '<strong style="color: #FFFFFF;">$1</strong>'
                            ), // Ensuring <strong> is also white
                          }}
                        />
                      </Box>
                    </Box>

                    <Box mt={5} display="flex" justifyContent="space-between">
                      <Box
                        style={{
                          width: "248px", // Assuming "Hug (248px)" means a fixed width of 248px
                          height: "79px", // Assuming "Hug (79px)" means a fixed height of 79px
                          gap: "10px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "49px",
                            height: "21px",
                            gap: "0px",
                            fontStyle: "Roboto",
                            color: "#FFFFFF99",
                            fontSize: "14px",
                            fontWeight: 300,
                            lineHeight: "21px",
                            letterSpacing: "-0.4px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                            marginBottom: "5px",
                          }}
                        >
                          Address
                        </Typography>
                        <Typography
                          style={{
                            width: "248px",
                            height: "48px",
                            gap: "0px",

                            fontStyle: "Roboto",
                            color: "#FFFFFF",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "-0.4px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                          }}
                        >
                          {address}
                        </Typography>
                      </Box>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                          <Box
                            style={{
                              width: "248px", // Assuming "Hug (248px)" means a fixed width of 248px
                              height: "55px", // Assuming "Hug (55px)" means a fixed height of 55px
                              gap: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                width: "49px",
                                height: "21px",
                                gap: "0px",
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "21px",
                                letterSpacing: "-0.4px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#FFFFFF99",
                                marginBottom: "5px",
                              }}
                            >
                              Website
                            </Typography>
                            <Typography
                              style={{
                                width: "248px",
                                height: "24px",
                                gap: "0px",
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                letterSpacing: "-0.4px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#FFFFFF",
                              }}
                            >
                              {website}
                            </Typography>
                          </Box>
                        </div>

                        <div>
                          <Box
                            style={{
                              width: "248px", // Assuming "Hug (248px)" refers to a fixed width of 248px
                              height: "55px", // Assuming "Hug (55px)" refers to a fixed height of 55px
                              gap: "10px",
                            }}
                            mt={8}
                          >
                            <Typography
                              style={{
                                width: "33px",
                                height: "21px",
                                gap: "0px",
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "21px",
                                letterSpacing: "-0.4px", // Simplified for readability
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#FFFFFF99",
                                marginBottom: "5px",
                              }}
                            >
                              Email
                            </Typography>
                            {Array.isArray(email) ? (
                              <Typography
                                style={{
                                  width: "248px",
                                  height: "24px",
                                  gap: "0px",
                                  fontStyle: "Roboto",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  lineHeight: "24px",
                                  letterSpacing: "-0.4px",
                                  textAlign: "left",
                                  textUnderlinePosition: "from-font",
                                  textDecorationSkipInk: "none",
                                  color: "#FFFFFF",
                                  whiteSpace: "nowrap", // Prevents wrapping
                                  overflow: "hidden", // Hides overflowing text
                                  textOverflow: "ellipsis", // Adds ... if text is too long
                                }}
                              >
                                {email.join(", ")}{" "}
                                {/* Join array items with comma and space */}
                              </Typography>
                            ) : (
                              <Typography
                                style={{
                                  width: "248px",
                                  height: "24px",
                                  gap: "0px",
                                  fontStyle: "Roboto",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  lineHeight: "24px",
                                  letterSpacing: "-0.4px",
                                  textAlign: "left",
                                  textUnderlinePosition: "from-font",
                                  textDecorationSkipInk: "none",
                                  color: "#FFFFFF",
                                }}
                              >
                                {email}
                              </Typography>
                            )}
                          </Box>
                        </div>
                      </div>
                      <Box
                        style={{
                          width: "77px", // Assuming "Hug (77px)" refers to a fixed width of 77px
                          height: "55px", // Assuming "Hug (55px)" refers to a fixed height of 55px
                          gap: "10px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "60px",
                            height: "21px",
                            gap: "0px",
                            fontStyle: "Roboto",
                            fontSize: "14px",
                            fontWeight: 300,
                            lineHeight: "21px",
                            letterSpacing: "-0.4px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                            color: "#FFFFFF99",
                            marginBottom: "5px",
                          }}
                        >
                          Duns ID
                        </Typography>
                        <Typography
                          style={{
                            width: "77px",
                            height: "24px",
                            gap: "0px",
                            fontStyle: "Roboto",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "-0.4px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                            color: "#FFFFFF",
                          }}
                        >
                          {dunsId}
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={-7} display="flex" justifyContent="space-between">
                      <Box
                        style={{
                          width: "248px", // Assuming "Hug (248px)" refers to a fixed width of 248px
                          height: "55px", // Assuming "Hug (55px)" refers to a fixed height of 55px
                          gap: "10px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "97px",
                            height: "21px",
                            gap: "0px",
                            fontStyle: "Roboto",
                            fontSize: "14px",
                            fontWeight: 300,
                            lineHeight: "21px",
                            letterSpacing: "-0.4px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                            color: "#FFFFFF99",
                            marginBottom: "5px",
                          }}
                        >
                          Contact number
                        </Typography>
                        <Typography
                          style={{
                            width: "248px",
                            height: "24px",
                            gap: "0px",
                            fontStyle: "Roboto",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "-0.4px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                            color: "#FFFFFF",
                          }}
                        >
                          {telephone}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>

              <Container
                sx={{
                  width: "1800px",
                  //   height: "720px",
                  padding: "16px",
                  borderRadius: "8px",
                  background: "#FFFFFF0F",
                  border: "1px solid #FFFFFF1F",
                  gap: "24px",
                  marginRight: "-1.5rem", // This centers the card
                }}
                ref={supplierRef}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    <LocalShippingOutlined
                      sx={{
                        width: "18px",
                        height: "18px",
                        padding: "1.5px 0.75px 1.5px 0.75px",
                        mr: 0.5, // Added margin to create spacing
                        color: "#FFFFFFCC",
                        fontSize: "12px",
                      }}
                    />
                    <Typography
                      component="div"
                      sx={{
                        fontStyle: "Roboto",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24.72px",
                        letterSpacing: "-0.4px",
                        textAlign: "left",
                        color: "#FFFFFFCC",
                      }}
                    >
                      Supplier Overview
                    </Typography>
                  </Box>

                  {/* Horizontal Line */}
                  <Divider
                    sx={{
                      borderColor: "#FFFFFF0F",
                      mb: 2,
                      borderBottom: "0.5px solid #FFFFFF0F", // Explicitly set a very thin border
                    }}
                  />

                  <Box
                    style={{
                      width: "1000px",
                      height: "631px",
                      paddingBottom: "20px",
                      gap: "40px",
                      margin: "auto",
                    }}
                  >
                    {/* First Row */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                        height: "177px",
                      }}
                    >
                      <Box sx={{ width: "240px", height: "54px", gap: "10px" }}>
                        <CustomTypography
                          sx={{
                            flex: 1,
                          }}
                        >
                          Parent Company
                        </CustomTypography>
                        <Box sx={{ width: "150px", height: "22px" }}>
                          {parentCompany?.map((company, index) => (
                            <Typography
                              key={index}
                              sx={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "21.63px",
                                letterSpacing: "-0.4px",
                                textAlign: "left",
                                color: "#FFFFFF",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                mt: 1, // add spacing between items if needed
                                // mb: 0.5 // add spacing between items if needed
                              }}
                            >
                              {company}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box sx={{ width: "240px", height: "54px", gap: "10px" }}>
                        <CustomTypography
                          sx={{
                            flex: 1,
                          }}
                        >
                          Headquarters
                        </CustomTypography>
                        <Box sx={{ width: "150px", height: "22px" }}>
                          {headquarters?.map((hq, index) => (
                            <Typography
                              key={index}
                              sx={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "21.63px",
                                letterSpacing: "-0.4px",
                                textAlign: "left",
                                color: "#FFFFFF",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                mt: 1, // add spacing between items if needed
                                // mb: 0.5 // add spacing between items if needed
                              }}
                            >
                              {hq}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box style={{ width: "350px", height: "177px" }}>
                        <CustomTypography
                          sx={{
                            flex: 1,
                          }}
                        >
                          Categories Operated
                        </CustomTypography>
                        <Box
                          style={{
                            width: "430px",
                            height: "300px",
                            overflowY: "auto", // Makes it vertically scrollable
                            // scrollbarWidth: "thin", // For Firefox scrollbar (optional)
                            "&::-webkit-scrollbar": {
                              width: "6px", // Thinner scrollbar
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "rgba(255, 255, 255, 0.1)", // Light background for the track
                              borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "rgba(255, 255, 255, 0.3)", // Semi-transparent white thumb
                              borderRadius: "10px",
                              "&:hover": {
                                background: "rgba(255, 255, 255, 0.5)", // Slightly lighter on hover
                              },
                            },
                          }}
                        >
                          {categoriesOperated?.map((co, index) => (
                            <Chip
                              key={index}
                              label={co}
                              sx={{
                                // backgroundColor: '#FFFFFF0F', // Custom background color
                                color: "#FFFFFF", // Text color
                                fontSize: "12px",
                                fontWeight: 350,
                                lineHeight: "18.52px",
                                fontStyle: "Roboto",
                                letterSpacing: "-0.4px",
                                // height: '28px',
                                border: "1px solid #FFFFFF0F",
                                borderRadius: "1000px",
                                mt: 1, // add spacing between items if needed
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    {/* Second Row */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                        height: "101px",
                      }}
                    >
                      <Box sx={{ width: "240px", height: "54px", gap: "10px" }}>
                        <CustomTypography
                          sx={{
                            flex: 1,
                            // textAlign: 'left'
                          }}
                        >
                          Year of Establishment
                        </CustomTypography>
                        <Box sx={{ width: "240px", height: "54px" }}>
                          {yearofest?.map((yos, index) => (
                            <Typography
                              key={index}
                              sx={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "21.63px",
                                letterSpacing: "-0.4px",
                                textAlign: "left",
                                color: "#FFFFFF",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                mt: 1, // add spacing between items if needed
                                // mb: 0.5 // add spacing between items if needed
                              }}
                            >
                              {Math.trunc(yos)}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box style={{ width: "240px", height: "101px" }}>
                        <CustomTypography
                          sx={{
                            flex: 1,
                          }}
                        >
                          Business Units
                        </CustomTypography>
                        <Box sx={{ width: "240px", height: "69px" }}>
                          {businessUnits?.map((bo, index) => (
                            <Chip
                              key={index}
                              label={bo}
                              sx={{
                                // backgroundColor: '#FFFFFF0F', // Custom background color
                                color: "#FFFFFF", // Text color
                                fontSize: "12px",
                                fontWeight: 350,
                                lineHeight: "18.52px",
                                fontStyle: "Roboto",
                                letterSpacing: "-0.4px",
                                // height: '28px',
                                border: "1px solid #FFFFFF0F",
                                borderRadius: "1000px",
                                mt: 1,
                              }}
                            />
                          ))}
                        </Box>
                      </Box>

                      <Box style={{ width: "350px", height: "101px" }}>
                        <CustomTypography
                          sx={{
                            flex: 1,
                          }}
                        >
                          Industries Served
                        </CustomTypography>
                        <Box
                          sx={{
                            width: "430px",
                            height: "69px",
                            overflowY: "auto", // Makes it vertically scrollable
                            // scrollbarWidth: "thin", // For Firefox scrollbar (optional)
                            "&::-webkit-scrollbar": {
                              width: "6px", // Thinner scrollbar
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "rgba(255, 255, 255, 0.1)", // Light background for the track
                              borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "rgba(255, 255, 255, 0.3)", // Semi-transparent white thumb
                              borderRadius: "10px",
                              "&:hover": {
                                background: "rgba(255, 255, 255, 0.5)", // Slightly lighter on hover
                              },
                            },
                          }}
                        >
                          {customerBase?.[0]
                            ?.replace(/\[\[|\]\]/g, "") // Remove the outer brackets
                            .split(",") // Split by comma to separate items
                            .map((cb, index) => (
                              <Chip
                                key={index}
                                label={cb.trim()} // Remove any extra spaces from each item
                                sx={{
                                  color: "#FFFFFF",
                                  fontSize: "12px",
                                  fontWeight: 350,
                                  lineHeight: "18.52px",
                                  fontStyle: "Roboto",
                                  letterSpacing: "-0.4px",
                                  border: "1px solid #FFFFFF0F",
                                  borderRadius: "1000px",
                                  mt: 1,
                                  mr: 1, // Adds spacing between Chips
                                }}
                              />
                            ))}
                        </Box>
                      </Box>
                    </Box>

                    {/* Third Row */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                        height: "139px",
                      }}
                    >
                      <Box sx={{ width: "240px", height: "54px" }}>
                        <CustomTypography
                          sx={{
                            // color: '#FFFFFF',
                            flex: 1,
                            // textAlign: 'left'
                          }}
                        >
                          No. of plants
                        </CustomTypography>
                        <Box>
                          {noOfPlants?.map((nop, index) => (
                            <Typography
                              key={index}
                              sx={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "21.63px",
                                letterSpacing: "-0.4px",
                                textAlign: "left",
                                color: "#FFFFFF",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                mt: 1, // add spacing between items if needed
                                // mb: 0.5 // add spacing between items if needed
                              }}
                            >
                              {isNaN(parseInt(nop)) ? "-" : parseInt(nop)}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box sx={{ width: "240px", height: "54px" }}>
                        <CustomTypography
                          sx={{
                            // color: '#FFFFFF',
                            flex: 1,
                          }}
                        >
                          Plant locations
                        </CustomTypography>
                        <Box sx={{ width: "240px", height: "54px" }}>
                          <Typography
                            sx={{
                              fontStyle: "Roboto",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "21.63px",
                              letterSpacing: "-0.4px",
                              textAlign: "left",
                              color: "#FFFFFF",
                              textUnderlinePosition: "from-font",
                              textDecorationSkipInk: "none",
                              mt: 1,
                            }}
                          >
                            {Array.isArray(plantLocations) &&
                            plantLocations.length > 0
                              ? plantLocations.join(" | ")
                              : "-"}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ width: "350px", height: "139px" }}>
                        <CustomTypography
                          sx={{
                            // color: '#FFFFFF',
                            flex: 1,
                          }}
                        >
                          Key products and services
                        </CustomTypography>
                        <Box
                          sx={{
                            width: "430px",
                            height: "80px",
                            overflowY: "auto", // Makes it vertically scrollable
                            // scrollbarWidth: "thin", // For Firefox scrollbar (optional)
                            "&::-webkit-scrollbar": {
                              width: "6px", // Thinner scrollbar
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "rgba(255, 255, 255, 0.1)", // Light background for the track
                              borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "rgba(255, 255, 255, 0.3)", // Semi-transparent white thumb
                              borderRadius: "10px",
                              "&:hover": {
                                background: "rgba(255, 255, 255, 0.5)", // Slightly lighter on hover
                              },
                            },
                          }}
                        >
                          {keyProductsAndServices?.map((kps, index) => (
                            <Chip
                              key={index}
                              label={kps}
                              sx={{
                                // backgroundColor: '#FFFFFF0F', // Custom background color
                                color: "#FFFFFF", // Text color
                                fontSize: "12px",
                                fontWeight: 350,
                                lineHeight: "18.52px",
                                fontStyle: "Roboto",
                                letterSpacing: "-0.4px",
                                // height: '28px',
                                border: "1px solid #FFFFFF0F",
                                borderRadius: "1000px",
                                mt: 1,
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    {/* Fourth Row */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                        height: "74px",
                      }}
                    >
                      <Box sx={{ width: "240px", height: "54px" }}>
                        <CustomTypography
                          sx={{
                            // color: '#FFFFFF',
                            flex: 1,
                            // textAlign: 'left'
                          }}
                        >
                          No. of employees
                        </CustomTypography>
                        <Box>
                          {noOfEmployees?.map((noe, index) => (
                            <Typography
                              key={index}
                              sx={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "21.63px",
                                letterSpacing: "-0.4px",
                                textAlign: "left",
                                color: "#FFFFFF",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                mt: 1, // add spacing between items if needed
                                // mb: 0.5 // add spacing between items if needed
                              }}
                            >
                              {parseInt(noe)}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box sx={{ width: "240px", height: "54px" }}>
                        <CustomTypography
                          sx={{
                            // color: '#FFFFFF',
                            flex: 1,
                          }}
                        >
                          Regions served
                        </CustomTypography>
                        <Box>
                          <Typography
                            sx={{
                              fontStyle: "Roboto",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "21.63px",
                              letterSpacing: "-0.4px",
                              textAlign: "left",
                              color: "#FFFFFF",
                              textUnderlinePosition: "from-font",
                              textDecorationSkipInk: "none",
                              mt: 1,
                            }}
                          >
                            {regionsServed?.join(", ")}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ width: "350px", height: "54px" }}>
                        <CustomTypography
                          sx={{
                            // color: '#FFFFFF',
                            flex: 1,
                          }}
                        >
                          Capacity
                        </CustomTypography>
                        <Box
                          sx={{
                            width: "430px",
                            height: "69px",
                            overflowY: "auto", // Makes it vertically scrollable
                            // scrollbarWidth: "thin", // For Firefox scrollbar (optional)
                            "&::-webkit-scrollbar": {
                              width: "6px", // Thinner scrollbar
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "rgba(255, 255, 255, 0.1)", // Light background for the track
                              borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "rgba(255, 255, 255, 0.3)", // Semi-transparent white thumb
                              borderRadius: "10px",
                              "&:hover": {
                                background: "rgba(255, 255, 255, 0.5)", // Slightly lighter on hover
                              },
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontStyle: "Roboto",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "21.63px",
                              letterSpacing: "-0.4px",
                              textAlign: "left",
                              color: "#FFFFFF",
                              textUnderlinePosition: "from-font",
                              textDecorationSkipInk: "none",
                              mt: 1,
                            }}
                          >
                            {capacity?.join(", ")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Container>

              <Container
                sx={{
                  width: "1400px",
                  //   height: "700px",
                  padding: "16px 0px 0px 0px",
                  borderRadius: "8px",
                  background: "#FFFFFF0F",
                  border: "1px solid #FFFFFF1F",
                  gap: "24px",
                  marginRight: "-1.5rem", // This centers the card
                }}
                ref={financialRef}
              >
                <CardContent>
                  {/* Fourth card content */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    <AttachMoney
                      sx={{
                        width: "24px", // Increase the width to 24px
                        height: "24px", // Increase the height to 24px
                        padding: "1.5px 0.75px 1.5px 0.75px",
                        mr: 0.5, // Added margin to create spacing
                        color: "#FFFFFFCC",
                      }}
                    />
                    <Typography
                      component="div"
                      sx={{
                        fontStyle: "Roboto",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24.72px",
                        letterSpacing: "-0.4px",
                        textAlign: "left",
                        color: "#FFFFFFCC",
                      }}
                    >
                      Financial information
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      width: "1100px",
                      height: "36px",
                      padding: "8px",
                      gap: "4px",
                      borderRadius: "4px",
                      backgroundColor: "#FFFFFF0F",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Info
                      sx={{
                        width: "14px",
                        height: "14px",
                        padding: "0.58px",
                        lineHeight: "20px",
                        color: "#D2D3E0",
                        mr: 0.3,
                      }}
                    />
                    <Typography
                      style={{
                        fontStyle: "Roboto",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                        color: "#D2D3E0",
                      }}
                    >
                      Latest revenue for {supplierName} is{" "}
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: 350,
                          lineHeight: "20px",
                          textAlign: "left",
                          textUnderlinePosition: "from-font",
                          textDecorationSkipInk: "none",
                        }}
                      >
                        {revenueUSD?.join(", ")}{" "}
                        {/* This joins the array items with a comma */}
                      </span>
                    </Typography>
                  </Box>

                  {/* <Box style={{ marginTop: "1.5rem" }}>
                      {/* <TableContainer component={Paper}> */}
                  {/* <Table
                        style={{
                          border: "1px solid #FFFFFF0F",
                          borderRadius: "6px",
                          overflow: "hidden", // This ensures the border radius is applied correctly
                          borderCollapse: "separate", // Important for border-radius to work
                          borderSpacing: 0, // Ensures no gaps between cells
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <TableCell
                                style={{
                                  borderWidth: "0px 1px 1px 0px",
                                  border: "0px 1px 1px 0px",
                                  borderStyle: "solid",
                                  borderColor: "#FFFFFF0F",
                                  borderRadius: "0px 1px 1px 0px",
                                  backgroundColor: "#353641B2",
                                  color: "#D2D3E0",
                                }}
                                key={index}
                              >
                                {column}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody
                          style={{
                            backgroundColor: "#FFFFFF1F",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              style={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#FFFFFF",
                                borderWidth: "0px 1px 1px 0px",
                                border: "0px 1px 1px 0px",
                                borderStyle: "solid",
                                borderColor: "#FFFFFF0F",
                                borderRadius: "0px 1px 1px 0px",
                              }}
                            >
                              Margin
                            </TableCell>
                            <TableCell
                              style={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "20px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#FFFFFF",
                                borderWidth: "0px 1px 1px 0px",
                                border: "0px 1px 1px 0px",
                                borderStyle: "solid",
                                borderColor: "#FFFFFF0F",
                                borderRadius: "0px 1px 1px 0px",
                              }}
                            >
                              undefined
                            </TableCell>
                            <TableCell
                              style={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "20px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#FFFFFF",
                                borderWidth: "0px 1px 1px 0px",
                                border: "0px 1px 1px 0px",
                                borderStyle: "solid",
                                borderColor: "#FFFFFF0F",
                                borderRadius: "0px 1px 1px 0px",
                              }}
                            >
                              undefined
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#FFFFFF",
                                borderWidth: "0px 1px 1px 0px",
                                border: "0px 1px 1px 0px",
                                borderStyle: "solid",
                                borderColor: "#FFFFFF0F",
                                borderRadius: "0px 1px 1px 0px",
                              }}
                            >
                              EBITDA
                            </TableCell>
                            <TableCell
                              style={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "20px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#FFFFFF",
                                borderWidth: "0px 1px 1px 0px",
                                border: "0px 1px 1px 0px",
                                borderStyle: "solid",
                                borderColor: "#FFFFFF0F",
                                borderRadius: "0px 1px 1px 0px",
                              }}
                            >
                              undefined
                            </TableCell>
                            <TableCell
                              style={{
                                fontStyle: "Roboto",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "20px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#FFFFFF",
                                borderWidth: "0px 1px 1px 0px",
                                border: "0px 1px 1px 0px",
                                borderStyle: "solid",
                                borderColor: "#FFFFFF0F",
                                borderRadius: "0px 1px 1px 0px",
                              }}
                            >
                              undefined
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      {/* </TableContainer> */}
                  {/* </Box>  */}

                  <Box
                    style={{
                      height: "300px",
                      display: "flex", // Ensures horizontal alignment
                      gap: "16px", // Space between the containers
                      justifyContent: "center", // Horizontally center the containers
                      alignItems: "center", // Vertically center the containers
                    }}
                  >
                    {/* First Container */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: 350,
                        color: "white",
                        // marginTop: '3rem'
                      }}
                    >
                      <Typography>Sales split across business units</Typography>
                      <Box
                        style={{
                          width: "550px",
                          height: "171px",
                          padding: "76px 20px",
                          gap: "6px",
                          border: "1px solid #FFFFFF0F",
                          borderRadius: "4px",
                          backgroundColor: "#FFFFFF0F",
                          alignItems: "center", // Vertically centers content
                          justifyContent: "center", // Horizontally centers content
                          boxSizing: "border-box", // Ensures padding is included in dimensions
                        }}
                      >
                        <Box
                          style={{
                            fontSize: "16px", // Adjust font size as needed
                            fontStyle: "Roboto",
                            fontWeight: 350,
                            color: "#FFFFFF99", // Subheading color
                            textAlign: "center",
                          }}
                        >
                          <LockOutlined
                            sx={{ width: "16px", height: "16px", mr: 1 }}
                          />
                          Requires access to annual reports or financial
                          databases
                        </Box>
                        {/* Content of the first container */}
                      </Box>
                    </div>

                    {/* Second Container */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: 350,
                        color: "white",
                        // marginTop: '3rem'
                      }}
                    >
                      <Typography>Credit ratings</Typography>
                      <Box
                        style={{
                          width: "550px",
                          height: "171px",
                          padding: "76px 20px",
                          gap: "6px",
                          border: "1px solid #FFFFFF0F",
                          borderRadius: "4px",
                          backgroundColor: "#FFFFFF0F",
                          alignItems: "center", // Vertically centers content
                          justifyContent: "center", // Horizontally centers content
                          boxSizing: "border-box", // Ensures padding is included in dimensions
                        }}
                      >
                        <Box
                          style={{
                            fontSize: "16px", // Adjust font size as needed
                            fontStyle: "Roboto",
                            fontWeight: 350,
                            color: "#FFFFFF99", // Subheading color
                            textAlign: "center",
                          }}
                        >
                          <LockOutlined
                            sx={{ width: "16px", height: "16px", mr: 1 }}
                          />
                          Requires access to annual reports or financial
                          databases
                        </Box>
                        {/* Content of the second container */}
                      </Box>
                    </div>
                  </Box>
                </CardContent>
              </Container>

              <Container
                sx={{
                  width: "1400px",
                  //   height: "810px",
                  padding: "16px 0px 0px 0px",
                  borderRadius: "8px",
                  background: "#FFFFFF0F",
                  border: "1px solid #FFFFFF1F",
                  gap: "16px",
                  marginRight: "-1.5rem", // This centers the card
                }}
                ref={swotRef}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    <BarChart
                      sx={{
                        width: "24px", // Increase the width to 24px
                        height: "24px", // Increase the height to 24px
                        padding: "1.5px 0.75px 1.5px 0.75px",
                        mr: 0.5, // Added margin to create spacing
                        color: "#FFFFFFCC",
                      }}
                    />
                    <Typography
                      component="div"
                      sx={{
                        fontStyle: "Roboto",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24.72px",
                        letterSpacing: "-0.4px",
                        textAlign: "left",
                        color: "#FFFFFFCC",
                      }}
                    >
                      SWOT Analysis
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      width: "1188px",
                      // height: "740px",
                      padding: "0px 0px 20px 0px",
                      gap: "8px",
                      marginTop: "1rem",
                    }}
                  >
                    <div style={boxStyle}>
                      <div style={rowStyle}>
                        <div style={swotContainerStyle}>
                          {/* Content for first container */}
                          <div style={borderBottomStyleSwot}>
                            <Typography style={headingStyleSwot}>
                              Strength
                            </Typography>
                          </div>
                          <div style={swotbulletpoints}>
                            <ul style={swotbulletStyle}>
                              {strengths.map((strength, index) => (
                                <li key={index} style={swotlistStyle}>
                                  {strength.name}
                                  <p style={swotdescStyle}>
                                    {strength.description}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div style={swotContainerStyle}>
                          {/* Content for first container */}
                          <div style={borderBottomStyleSwot}>
                            <Typography style={headingStyleSwot}>
                              Weaknesses
                            </Typography>
                          </div>
                          <div style={swotbulletpoints}>
                            <ul style={swotbulletStyle}>
                              {weaknesses.map((weakness, index) => (
                                <li key={index} style={swotlistStyle}>
                                  {weakness.name}
                                  <p style={swotdescStyle}>
                                    {weakness.description}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div style={rowStyle}>
                        <div style={swotContainerStyle}>
                          {/* Content for first container */}
                          <div style={borderBottomStyleSwot}>
                            <Typography style={headingStyleSwot}>
                              Threats
                            </Typography>
                          </div>
                          <div style={swotbulletpoints}>
                            <ul style={swotbulletStyle}>
                              {threats.map((threat, index) => (
                                <li key={index} style={swotlistStyle}>
                                  {threat.name}
                                  <p style={swotdescStyle}>
                                    {threat.description}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div style={swotContainerStyle}>
                          {/* Content for first container */}
                          <div style={borderBottomStyleSwot}>
                            <Typography style={headingStyleSwot}>
                              Opportunities
                            </Typography>
                          </div>
                          <div style={swotbulletpoints}>
                            <ul style={swotbulletStyle}>
                              {opportunities.map((opts, index) => (
                                <li key={index} style={swotlistStyle}>
                                  {opts.name}
                                  <p style={swotdescStyle}>
                                    {opts.description}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </CardContent>
              </Container>

              <Container
                sx={{
                  width: "1600px",
                  //   height: "426px",
                  padding: "16px 0px 0px 0px",
                  borderRadius: "8px",
                  background: "#FFFFFF0F",
                  border: "1px solid #FFFFFF1F",
                  gap: "16px",
                  marginRight: "-1.5rem", // This centers the card
                }}
                ref={awardsRef}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    <EmojiEventsOutlined
                      sx={{
                        width: "24px", // Increase the width to 24px
                        height: "24px", // Increase the height to 24px
                        padding: "1.5px 0.75px 1.5px 0.75px",
                        mr: 0.5, // Added margin to create spacing
                        color: "#FFFFFFCC",
                      }}
                    />
                    <Typography
                      component="div"
                      sx={{
                        fontStyle: "Roboto",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24.72px",
                        letterSpacing: "-0.4px",
                        textAlign: "left",
                        color: "#FFFFFFCC",
                      }}
                    >
                      Awards and recognition
                    </Typography>
                  </Box>
                  <Box style={{ gap: "12px", marginTop: "1rem" }}>
                    {awardsAndRecognition.map((award, index) => (
                      <div key={index} style={cardStyle}>
                        <div style={iconContainerStyle}>
                          <img src={Awards} />
                        </div>
                        <div style={contentStyle}>
                          <Typography
                            // variant="h6"
                            style={{
                              fontWeight: 350,
                              marginBottom: "8px",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              fontStyle: "Roboto",
                              lineHeight: "21.6px",
                              paragraph: "30px",
                            }}
                          >
                            {award.title}
                          </Typography>
                          <Typography
                            // variant="body2"
                            style={{
                              maxWidth: "900px",
                              fontStyle: "Roboto",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "21.63px",
                              textAlign: "left",
                              textDecorationLine: "underline",
                              textDecorationStyle: "solid",
                              textUnderlinePosition: "from-font",
                              textDecorationSkipInk: "none",
                              color: "#FFFFFF66",
                            }}
                          >
                            {award.description}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </Box>
                </CardContent>
              </Container>

              <Container
                sx={{
                  width: "1600px",
                  // height: "162px",
                  //   padding: "16px 0px 0px 0px",
                  borderRadius: "8px",
                  background: "#FFFFFF0F",
                  border: "1px solid #FFFFFF1F",
                  gap: "16px",
                  marginRight: "-1.5rem", // This centers the card
                }}
                ref={esgRef}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      mb: 1,
                      borderBottom: "1px solid #FFFFFF0F",
                      padding: "20px",
                    }}
                  >
                    <GridViewOutlined
                      sx={{
                        width: "24px", // Increase the width to 24px
                        height: "24px", // Increase the height to 24px
                        padding: "1.5px 0.75px 1.5px 0.75px",
                        mr: 0.5, // Added margin to create spacing
                        color: "#FFFFFFCC",
                      }}
                    />
                    <Typography
                      component="div"
                      sx={{
                        fontStyle: "Roboto",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24.72px",
                        letterSpacing: "-0.4px",
                        textAlign: "left",
                        color: "#FFFFFFCC",
                      }}
                    >
                      ESG
                    </Typography>
                  </Box>
                  <Box style={boxStyleESG}>
                    <div style={headingContainerStyle}>
                      <Typography style={headingStyleESG}>Ethnicity</Typography>
                      <Typography style={valueStyleESG}>
                        {ethinicity.join(", ")}
                      </Typography>
                    </div>
                    <div style={headingContainerStyle}>
                      <Typography style={headingStyleESG}>Ownership</Typography>
                      <Typography style={valueStyleESG}>
                        {ownership.join(", ")}
                      </Typography>
                    </div>
                    <div style={headingContainerStyle}>
                      <Typography style={headingStyleESG}>
                        Small Business Classification
                      </Typography>
                      <Typography style={valueStyleESG}>
                        {smallBusinessClassification.join(", ")}
                      </Typography>
                    </div>
                    <div style={headingContainerStyle}>
                      <Typography style={headingStyleESG}>
                        Scope 1,2,3 targets
                      </Typography>
                      <Typography style={valueStyleESG}>
                        {scope1_2_3_Targets
                          ? scope1_2_3_Targets.join(", ")
                          : "N/A"}
                      </Typography>
                    </div>
                  </Box>
                </CardContent>
              </Container>
            </Box>
          </Box>
        </div>
      </Box>
      {/* </Box> */}
    </div>
  );
};

export default SupplierProfile;
